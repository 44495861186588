import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    useTheme,
    TableCell,
    styled,
    tableCellClasses,
    Grid,
    Typography
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import { useEffect } from 'react';

const MonitoredValuesTable = ({ data }) => {
    const theme = useTheme();
    const StyledSubTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledTableHead = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} lg={12} md={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={10} lg={10} md={10}>
                            <Typography variant="h4">{data.title}</Typography>
                        </Grid>
                        {data.status && (
                            <Grid item xs={2} lg={2} md={2} align="right">
                                <Chip chipcolor={data.status.type} label={data.status.content} />
                            </Grid>
                        )}
                    </Grid>
                </Grid> */}
                <Grid item xs={12} lg={12} md={12}>
                    <TableContainer>
                        <Table
                            size="small"
                            sx={{
                                border: '1px solid',
                                borderColor: theme.palette.common.grey
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    {data.title?.map((item, index) => (
                                        <StyledTableHead>{item}</StyledTableHead>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.contents?.map((item, index) => (
                                    <TableRow>
                                        {item?.map((cell, index) => (
                                            <StyledSubTableCell>{cell}</StyledSubTableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default MonitoredValuesTable;
