import {
    Box,
    Grid,
    Button,
    Typography,
    IconButton,
    CardContent,
    CardActions,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MainCard from 'ui-component/cards/MainCard';
import Modal from '@mui/material/Modal';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ProviderUserAgreementCard = () => {
    const navigate = useNavigate();
    const rootRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    return (
        <Grid container direction="column" xs={12} lg={12} spacing={2}>
            <Grid item alignItems="center" spacing={2}>
                <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 100px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 3, border: '0.5px solid #E3E3E3' }}>
                        <p align="justify">
                            <strong>Please read the below Terms and Conditions before using this website</strong>. Use of the website
                            indicates acceptance of the Terms and Conditions and indicates that you have read, understand, and agree to be
                            bound by these Terms and Conditions, whether or not you have registered with the site.
                        </p>
                        <p align="justify">
                            <strong>
                                IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE,
                                SERVICES, OR CONTENT.
                            </strong>
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            Vital eCare IS NOT A HEALTH CARE PROVIDER
                        </Typography>
                        <p align="justify">
                            Vital eCare provides health information derived from reputable sources such as the National Institutes of Health
                            (NIH), the Centers for Disease Control and Prevention (CDC), and the U.S. Department of Health and Human
                            Services (USDHHS). However, Vital eCare IS NOT A HEALTH CARE PROVIDER. OUR INFORMATION IS NOT INTENDED TO
                            REPLACE OR BE A SUBSTITUTE FOR MEDICAL ADVICE FROM A HEALTH CARE PROFESSIONAL. ALWAYS CONSULT YOUR HEALTH CARE
                            PROVIDER BEFORE MAKING ANY DECISIONS REGARDING YOUR OWN HEALTH CARE. IN CASE OF AN EMERGENCY, CONTACT YOUR
                            HEALTH CARE PROVIDER OR LOCAL EMERGENCY ROOM.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            Vital eCare DOES NOT GUARANTEE INFORMATION
                        </Typography>
                        <p align="justify">
                            Vital eCare DOES NOT GUARANTEE THE VALIDITY OR ACCURACY OF INFORMATION AND GUIDELINES PROVIDED ON THE SITE. YOU
                            ACKNOWLEDGE AND AGREE THAT Vital eCare DOES NOT VALIDATE, ENSURE THE ACCURACY OF, OR ENDORSE THE INFORMATION OR
                            YOUR USE OF INFORMATION WE DELIVER OR PRESENT FROM THIRD PARTIES, INCLUDING THE PERSONAL HEALTH INFORMATION SENT
                            TO YOU OR TO Vital eCare ON YOUR BEHALF FROM YOUR HEALTH CARE PROVIDERS. Vital eCare IS NOT ACTING AS THE AGENT
                            OF, OR ON BEHALF OF, ANY THIRD PARTY. IF YOU BELIEVE INFORMATION SENT TO YOU FROM YOUR HEALTH CARE PROVIDER IS
                            NOT ACCURATE, YOU AGREE THAT IT IS YOUR RESPONSIBILITY TO COMMUNICATE YOUR CONCERN WITH YOUR PROVIDER.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            Vital eCare IS NOT A LEGAL OR FINANCIAL ADVISOR
                        </Typography>
                        <p align="justify">
                            NEITHER Vital eCare NOR THE SERVICES ARE INTENDED TO PROVIDE LEGAL, HEALTH, OR FINANCIAL ADVICE. Vital eCare IS
                            NOT A HEALTH CARE PROVIDER OR FINANCIAL PLANNER OR ADVISOR. As part of our Services, we assist you in
                            organizing, understanding, and managing your personal health record, health care spending and preventive health
                            information. Our guidelines are broad in nature and not intended to replace the specific directions of your
                            health care provider. General information and advice obtained through the Services may not be appropriate or
                            relevant for your personal situation. Accordingly, before making any final decisions or revising any health
                            care-related strategies, please consider obtaining additional information and advice from professional advisors
                            who are familiar with your personal circumstances.
                        </p>

                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            NO RESPONSIBILITY FOR REWARDS
                        </Typography>
                        <p align="justify">
                            Vital eCare is not responsible for the distribution, verification, delivery or payment of rewards posted on the
                            site by your network of Vital eCares, employers etc. The person/organization posting the reward shall be solely
                            responsible for its fulfillment.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            MEMBER ACCOUNT, PASSWORD, AND SECURITY
                        </Typography>
                        <p align="justify">
                            We use commercially available tools such as security certificates and encryption of personal identification on
                            our website. Unfortunately, data transmission over the Internet is not completely secure. While we try to
                            protect your personal information after it is received by us, we cannot guarantee the security of any
                            information you transmit to us, and you do so at your own risk.
                        </p>
                        <p align="justify">
                            To open an account with Vital eCare, you must complete the registration process by providing us with current,
                            complete, and accurate information as prompted by the website registration form. You also will choose a password
                            and a username. You are entirely responsible for maintaining the confidentiality of your password and account.
                            Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to
                            immediately notify Vital eCare at <a href="mailto:support@vitaleCare.com">support@vitaleCare.com</a> of any
                            unauthorized use of your account or any other breach of security. Vital eCare will not be liable for any loss
                            that you may incur as a result of someone else using your password or account, either with or without your
                            knowledge. However, you could be held liable for losses incurred by Vital eCare or another party due to someone
                            else using your account or password. You may not use anyone else&apos;s account at any time, without the
                            permission of the account holder.
                        </p>
                        <p align="justify">UNDER NO CIRCUMSTANCES WILL WE REQUEST YOUR PASSWORD.</p>
                        <p align="justify">
                            Remember to log out of your account and close your browser window when you have finished. This helps prevent
                            others from accessing your personal information and correspondence.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            FOR USE BY ADULTS 18 YEARS OF AGE OR OLDER
                        </Typography>
                        <p align="justify">
                            The information and tools available on the Vital eCare website should only be handled by individuals 18 years of
                            age or older. Sections of the site contain information regarding sexual activity and sexual disease, so children
                            should not access the site.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            RIGHTS YOU GRANT TO Vital eCare
                        </Typography>
                        <p align="justify">
                            As part of our Service, with your permission Vital eCare may access your personally identifiable information
                            (&quot;PII&quot;) from your providers, health insurance companies, and employers. PII many include data you have
                            entered, received, or stored related to you or family member&apos;s records you have created in Vital eCare. You
                            are hereby authorizing Vital eCare to create, receive, maintain, transmit, and use your PII for the proper
                            management and administration of Vital eCare and in order to provide the Services to you, including, but not
                            limited to, data analysis necessary to review, improve, or validate a product, feature, or service offered in
                            connection with the services, or to carry out the legal responsibilities of Vital eCare..
                        </p>
                        <p align="justify">
                            Under certain circumstances Vital eCare may be required to return your PII to a provider, insurance company, or
                            employer who provided it to Vital eCare, but information you have received and stored once you have created an
                            Vital eCare account will not be removed without prior notification.
                        </p>
                        <p align="justify">
                            You further authorize Vital eCare and its business partners to create, receive, maintain, transmit, use, and
                            disclose de-identified data sets from your PII, provided that the method of de-identification complies with
                            HIPAA (the &quot;De-Identified Data&quot;), and create, receive, maintain, transmit, use, and disclose
                            De-Identified Data, for any purpose permitted by applicable law.
                        </p>
                        <p align="justify">
                            When using Vital eCare to send information, you authorize Vital eCare to send such PII to the recipient you
                            indicate and take responsibility for carefully sending only that information you wish to share with the
                            recipient, as well as carefully reviewing the recipient&apos;s address information.
                        </p>
                        <p align="justify">
                            You may also be submitting your Login Credentials (username and password) from your provider&apos;s
                            &quot;Patient Portals&quot; and Health Insurance Company Portals to Vital eCare. You represent that you are
                            entitled to submit such Login Credentials to Vital eCare for the purpose of receiving the Service, without any
                            obligation by Vital eCare to pay any fees or other limitations.
                        </p>
                        <p align="justify">
                            By using the Services, you hereby authorize Vital eCare, to access your Health care Information from your
                            providers, insurance companies, and employers, and return it to them as described above. You hereby authorize
                            and permit Vital eCare to use and store Login Credentials submitted by you to Vital eCare to accomplish the
                            foregoing and to configure the Services. For purposes of these Terms of Service and solely to provide the
                            Healtvital ecareare Information to you as part of the Services, you authorize Vital eCare to access the
                            websites, retrieve your Health Information, and use your Login Credentials and Healtvital ecareare Information
                            with the full power and authority to perform each thing necessary in connection with such activities, as you
                            could do in person, for the purpose of providing the Services.
                        </p>
                        <p align="justify">
                            YOU ACKNOWLEDGE AND AGREE THAT WHEN Vital eCare ACCESSES AND RETRIEVES HEALTH CARE INFORMATION FROM THE
                            WEBSITES, Vital eCare IS NOT ACTING AS THE AGENT OF, OR ON BEHALF OF, ANY THIRD PARTY.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            TERMINATION AND ACCOUNT CANCELLATION
                        </Typography>
                        <p align="justify">
                            If you breach any of these Terms and Conditions, Vital eCare will have the right to immediately suspend or
                            disable your Account at its sole discretion and without prior notice to you. In addition, Vital eCare reserves
                            the right to revoke your access to and use of the Services and content at any time, with or without cause.
                        </p>
                        <p align="justify">
                            To unregister (cancel) your account from Vital eCare, please send an e-mail to{' '}
                            <a href="mailto:support@vitaleCare.com">support@vitaleCare.com</a> requesting that your account be deleted.
                            Please note that unregistering will result in deletion of all of your account information.
                        </p>
                        <p align="justify">
                            UPON ANY TERMINATION OR CANCELLATION OF YOUR ACCOUNT, ANY INFORMATION THAT YOU ACCESS VIA THE SITE OR THE
                            SERVICES WILL NO LONGER BE ACCESSIBLE BY YOU.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            TRADEMARKS AND COPYRIGHTS
                        </Typography>
                        <p align="justify">
                            Vital eCare&reg;, Personal Health Network(SM), and Transforming Clutter to Clarity&reg; trademarks or service
                            marks, and/or trade dress of Vital eCare, Inc. All other trademarks, product names, and company names or logos
                            cited herein are the property of their respective owners. The images used in this website are licensed from
                            Comstock.com operated by Jupitermedia Corporation and from BigStockPhoto.com. The contents of this website are
                            subject to and protected by: Copyright &copy; 2009-2020 Vital eCare&reg;, Inc. ALL RIGHTS RESERVED.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            LIMITS ON USE
                        </Typography>
                        <p align="justify">
                            This website is owned and operated by Vital eCare, Inc., and is for the user&apos;s personal, noncommercial use.
                            You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative
                            works from, transfer, or sell in any way any information, documents, graphics, software, products or services
                            (&quot;Materials&quot;) obtained from this website, except that you may view the website content in its present
                            form for personal, noncommercial use, provided you keep intact all copyright and other proprietary notices. You
                            recognize that unauthorized use of website content may subject you to civil or criminal liability.
                        </p>

                        <Typography align="justify" variant="h5" pt={2}>
                            DISCLAIMER OF WARRANTIES
                        </Typography>
                        <p align="justify">
                            THE MATERIALS PUBLISHED ON THIS WEBSITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. Vital eCare, INC. DOES
                            NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS IN THIS
                            SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE TO THE FULLEST EXTENT ALLOWED BY
                            APPLICABLE LAW. YOU (AND NOT Vital eCare) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR
                            CORRECTION.
                        </p>
                        <p align="justify">
                            Vital eCare, INC. DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN ITS WEBSITE MATERIALS WILL BE UNINTERRUPTED
                            OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE
                            OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                        </p>
                        <p align="justify">
                            Vital eCare MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE MATERIALS CONTAINED ON THIS WEBSITE FOR ANY
                            PURPOSE. ALL SUCH MATERIALS ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                            IMPLIED, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, Vital eCare HEREBY TO THE FULLEST EXTENT
                            PERMITTED BY APPLICABLE LAW DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE MATERIALS, INCLUDING ALL
                            IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                            NONINFRINGEMENT.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            LIMITATIONS OF LIABILITY
                        </Typography>
                        <p align="justify">
                            IN NO EVENT SHALL Vital eCare BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR
                            CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS WEBSITE OR WITH THE DELAY OR
                            INABILITY TO USE THIS WEBSITE, OR FOR THE MATERIALS OBTAINED THROUGH THIS WEBSITE, OR OTHERWISE ARISING OUT OF
                            THE USE OF THIS WEBSITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF Vital eCare HAS
                            BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/ JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                            LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                        </p>
                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            PRIVACY STATEMENT
                        </Typography>
                        <p align="justify">
                            The Internet is not a completely secure environment. Vital eCare treats all communications in a confidential
                            manner. Please read the following policy to understand how your personal information will be used when you
                            utilize our Services. In doing so{' '}
                            <strong>You have the following rights with respect to your health information:</strong>
                        </p>
                        <ul align="justify">
                            <li>
                                The right to request restrictions on certain uses of your health information; however we are not required to
                                agree to your request.
                            </li>
                            <li>
                                The right to request, in writing, the manner or method by which we may contact you to furnish confidential
                                communications about your health information (i.e., fax, voicemail, etc.). (Refer to our Consent to Release
                                Protected Health Information) You are obligated to notify us, in writing, of any changes to your request.
                            </li>
                            <li>The right to review your health information and receive a copy of your health information.</li>
                            <li>
                                In limited circumstances, the right to ask us, in writing, to amend your health information, however we
                                reserve the right to deny your request. If your request for amendment is denied, we will provide you with
                                information about the basis of our denial.
                            </li>
                            <li>
                                The right to receive an accounting of disclosures of your health information, except those disclosures
                                related to treatment, payment or health operations. The exception also covers disclosures previously listed
                                in this disclosure that do not require your authorization.
                            </li>
                            <li>The right to receive a copy of this notice in writing.</li>
                        </ul>

                        <Typography align="justify" variant="h5" pt={2}>
                            We have the following obligations:
                        </Typography>
                        <ul align="justify">
                            <li>
                                We are required by law to maintain the privacy of your Protected Health Information, to provide you with a
                                notice of our legal duties and Notice of Privacy Practices and obtain a written acknowledgement that it has
                                been provided to you.
                            </li>
                            <li>
                                We are required to abide by the terms of the notice, including privacy agreements with Trading Partners
                                and/or Business Associates with whom we contract or do business and by maintaining physical, electronic and
                                work practice controls that comply with suggested federal regulations to guard your PHI.
                            </li>
                            <li>
                                We are required to advise you of any changes we make in the terms of our Notice of Privacy Practices post
                                the amended Notice and make a copy available to you upon request.
                            </li>
                        </ul>
                        <p align="justify">
                            <strong>Complaints:</strong> If you feel we have violated your privacy rights, or have questions concerning our
                            Notice of privacy practices, you may contact or file a written complaint to <strong>Privacy Officer</strong>, at
                            the following address:
                        </p>
                        <Divider />
                        <p align="justify">
                            or with the federal agency in charge of enforcing patients&apos; privacy rights. That address is:{' '}
                            <strong>
                                Office for Civil Rights, U.S. Department of Health and Human Services, 200 Independence Avenue, S.W., Room
                                509F, HHS Building, Washington, D.C.20201
                            </strong>
                        </p>

                        <Divider />

                        <Typography align="justify" variant="h5" pt={2}>
                            Anonymous Information
                        </Typography>
                        <p align="justify">
                            We also may obtain anonymous information, which may be used by Vital eCare for marketing purposes or for
                            improving the Services we offer. This might include statistics such as the gender or age of our users. It would
                            not include information that would identify any particular user. Vital eCare also may share the anonymous
                            information it collects, in aggregate form, with advertisers and other partners.
                        </p>
                        <Divider />
                        <Typography align="justify" variant="h5" pt={2}>
                            IP Addresses
                        </Typography>

                        <p align="justify">
                            Vital eCare will collect IP addresses for systems administration, to report aggregate information, to
                            troubleshoot problems, and to audit and improve the use of our website. We will use IP addresses to identify a
                            user only when necessary to enforce compliance with our Terms and Conditions or to protect our service, site,
                            customers, or others.
                        </p>
                        <Divider />
                        <Typography align="justify" variant="h5" pt={2}>
                            Partnerships
                        </Typography>

                        <p align="justify">
                            Please be aware that certain content providers may have privacy policies and terms of use that are separate and
                            distinct from the policies set forth in the Vital eCare Terms and Conditions displayed on this page. In
                            addition, it is possible that a content provider may use cookies.
                        </p>
                        <Divider />
                        <Typography align="justify" variant="h5" pt={2}>
                            Changes to content and Terms and Conditions
                        </Typography>

                        <p align="justify">
                            Vital eCare frequently updates its health information content. Be aware that any and all information on the
                            website is subject to change without notice. Vital eCare is not liable for actions taken as a result of viewing
                            content on the website. In addition, Vital eCare reserves the right to make changes to the Terms and Conditions
                            without notice.
                        </p>
                        <Divider />
                        <Typography align="justify" variant="h5" pt={2}>
                            DISPUTE RESOLUTION
                        </Typography>

                        <p align="justify">
                            You and Vital eCare agree that any dispute, claim, or controversy arising out of or relating to these Terms and
                            Conditions or the breach, termination, enforcement, interpretation, or validity thereof, or the use of the
                            website, services or application (collectively, &quot;Disputes&quot;) will be settled by binding arbitration,
                            except that each party retains the right to bring an individual action in small claims court and the right to
                            seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or
                            threatened infringement, misappropriation, or violation of a party&apos;s copyrights, trademarks, trade secrets,
                            patents, or other intellectual property rights. You acknowledge and agree that you and Vital eCare are each
                            waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class
                            action or representative proceeding. Further, unless both you and Vital eCare otherwise agree in writing, the
                            arbitrator may not consolidate more than one person&apos;s claims, and may not otherwise preside over any form
                            of any class or representative proceeding. If this specific paragraph is held unenforceable, then the entirety
                            of this Vital eCare otherwise agree in writing, the arbitrator may not &quot;Dispute Resolution&quot; section
                            will be deemed void. Except as provided in the preceding sentence, this &quot;Dispute Resolution&quot;section
                            will survive any termination of these Terms and Conditions.
                        </p>
                        <br />
                        <br />
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate('/onboarding/providerprivacypolicy')}
                                    >
                                        I agree
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" color="primary" fullWidth onClick={handleClick}>
                                        I do not agree
                                    </Button>
                                </Grid>
                                {open && (
                                    <div>
                                        {/* <Modal
                                            disablePortal
                                            disableEnforceFocus
                                            disableAutoFocus
                                            open
                                            aria-labelledby="server-modal-title"
                                            aria-describedby="server-modal-description"
                                            sx={{
                                                display: 'flex',
                                                p: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            container={() => rootRef.current}
                                        >
                                            <MainCard
                                                sx={{
                                                    width: 450,
                                                    zIndex: 1
                                                }}
                                                title="User Agreement"
                                                content={false}
                                                secondary={
                                                    <IconButton size="large">
                                                        <CloseIcon fontSize="small" onClick={handleClose} />
                                                    </IconButton>
                                                }
                                            >
                                                <CardContent>
                                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                                        You will not be able to use the Vital eCare services without accepting the User
                                                        Agreement.
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Grid container justifyContent="center">
                                                        <Button variant="contained" color="primary" onClick={handleClose} p={3}>
                                                            Ok
                                                        </Button>
                                                    </Grid>
                                                </CardActions>
                                            </MainCard>
                                        </Modal> */}
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            sx={{ p: 3 }}
                                        >
                                            {open && (
                                                <>
                                                    <DialogTitle id="alert-dialog-title">User Agreement</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <Typography variant="body2" component="span">
                                                                You will not be able to use the Vital eCare services without accepting the
                                                                User Agreement.
                                                            </Typography>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions style={{ justifyContent: 'space-between' }} sx={{ pl: 2.5 }}>
                                                        <Button variant="contained" size="small" onClick={handleClose} autoFocus>
                                                            Ok
                                                        </Button>
                                                    </DialogActions>
                                                </>
                                            )}
                                        </Dialog>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </PerfectScrollbar>
            </Grid>
        </Grid>
    );
};

export default ProviderUserAgreementCard;
