import { memo } from 'react';
import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

const MenuList = () => {
    const navItems = menuItem.items.map((item) => (
        <div key={item.id}>
            {item.type === 'group' ? (
                <NavGroup item={item} />
            ) : (
                <Typography variant="h6" color="error" align="center">
                    Menu Items Error
                </Typography>
            )}
        </div>
    ));

    return <>{navItems}</>;
};

export default memo(MenuList);
