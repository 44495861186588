import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
// material-ui

import { Button, Grid, TextField } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports

import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import { FormattedMessage } from 'react-intl';

// import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';

// ============================|| PATIENT - SIGNUP ||============================ //

const PatientSignUpCard = ({ ...others }) => {
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    // const [strength, setStrength] = React.useState(0);
    // const [level, setLevel] = React.useState();

    const { patientUsernamePasswordSignIn } = useAuth();

    // const changePassword = (value) => {
    //     const temp = strengthIndicatorNumFunc(value);
    //     setStrength(temp);
    //     setLevel(strengthColor(temp));
    // };
    // useEffect(() => {
    //     changePassword('123456');
    // }, []);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                        confirmPassword: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .min(1, 'Mininum 1 characters')
                            .max(15, 'Maximum 15 characters')

                            .required('Username is required'),

                        password: Yup.string().max(255).required('Password is required'),
                        confirmPassword: Yup.string().when('password', {
                            is: (val) => !!(val && val.length > 0),
                            then: Yup.string().oneOf([Yup.ref('password')], 'Both Password must be match!')
                        })
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            await patientUsernamePasswordSignIn(values.username, values.password).then(
                                () => {},
                                (err) => {
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            );
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="newusername"
                                        name="newtemporaryusername"
                                        label="New Username"
                                        // value={values.username}
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.username && Boolean(errors.username)}
                                        helperText={touched.username && errors.username}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="newpassword"
                                        name="newpassword"
                                        label="New Password"
                                        type="password"
                                        size="small"
                                        // value={values.password}
                                        onChange={handleChange}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="confirmpassword"
                                        name="confirmpassword"
                                        label="Reenter Password"
                                        type="password"
                                        // value={values.confirmPassword}
                                        size="small"
                                        onChange={handleChange}
                                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                        helperText={touched.confirmPassword && errors.confirmPassword}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        // size="small"
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={() => navigate('../patientonboarding/SecurityQuestions')}
                                    >
                                        <FormattedMessage id="Next" />
                                    </Button>
                                </Grid>
                            </Grid>

                            {/* {strength !== 0 && (
                                <FormControl fullWidth>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <Box
                                                    style={{ backgroundColor: level?.color }}
                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                    {level?.label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormControl>
                            )} */}
                        </form>
                    )}
                </Formik>
            </Grid>
        </>
    );
};

export default PatientSignUpCard;
