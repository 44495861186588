// material-ui
import { Table, TableBody, Grid, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

// table data
function createData(medication, dose, route, frequency, status, pdate, Rxcode) {
    return { medication, dose, route, frequency, status, pdate, Rxcode };
}

const rows = [createData('LIPITOR (Oral Pill)', '80 mg TABLET FILM COATED', 'Oral', 'Daily', 'Active', '02/02/2021', 262095)];

// ==============================|| TABLE - DENSE ||============================== //

export default function HSMedicationsTable() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard>
            <Grid item>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Medications
                </MuiTypography>
            </Grid>
            <TableContainer sx={{ minWidth: 500, border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Medication</StyledTableCell>
                            <StyledTableCell align="left">Dose/ Format</StyledTableCell>
                            <StyledTableCell align="left">Route</StyledTableCell>
                            <StyledTableCell align="left">Frequency</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Prescribed Date</StyledTableCell>
                            <StyledTableCell align="left">RxNorm Code</StyledTableCell>
                            {/* <TableCell sx={{ pr: 3 }} align="right">
                                Protein&nbsp;(g)
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover key={row.name}>
                                <TableCell align="left">{row.medication}</TableCell>
                                <TableCell align="left">{row.dose}</TableCell>
                                <TableCell align="left">{row.route}</TableCell>
                                <TableCell align="left">{row.frequency}</TableCell>
                                <TableCell align="left">{row.status}</TableCell>
                                <TableCell align="left">{row.pdate}</TableCell>
                                <TableCell align="left">{row.Rxcode}</TableCell>
                                {/* <TableCell sx={{ pr: 3 }} align="right">
                                    {row.protein}
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubCard>
    );
}
