import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Button, Grid, Stack, Typography, useMediaQuery, FormControlLabel, Checkbox } from '@mui/material';

// project imports
// import AuthWrapper2 from 'views/pages/authentication/AuthWrapper2';
// import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import Logo from 'ui-component/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { color, fontSize } from '@mui/system';
import pagenotfound from '../../assets/images/404/Vital-e-logo.png';
import warning from '../../assets/images/404/warning.svg';
// import ProxyHeadingCard from './ProxyHeadingCard';
// ================================|| AUTH2 - LOGIN ||================================ //

const Somethingwrong = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    const boxStyle = {
        backgroundColor: isHover ? '#1e88e5' : '#2196f3',
        fontSize: '22px'
    };
    return (
        <Grid
            container
            justifyContent={matchDownSM ? 'center' : 'space-between'}
            alignItems="center"
            sx={{ background: 'linear-gradient(81deg, rgba(254,254,254,1) 0%, rgba(226,230,232,1) 100%);', backgroundSize: 'cover' }}
        >
            <Grid item md={12} lg={12} xs={12} sx={{ minHeight: '100vh' }}>
                <Grid
                    sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}
                    container
                    alignItems={matchDownSM ? 'center' : 'flex-start'}
                    justifyContent={matchDownSM ? 'center' : 'space-between'}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: { xs: 'calc(100vh - 68px)', md: 'calc(100vh - 152px)' }, flexDirection: 'column' }}
                    >
                        <Stack>
                            <span style={{ width: '100%', maxWidth: '500px', display: 'block', paddingBottom: '40px' }}>
                                <img src={pagenotfound} alt="forbidden" width="100%" />
                            </span>
                        </Stack>
                        <Stack justifyContent="center" alignItems="center" spacing={5} m={2} sx={{ position: 'relative' }}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            textAlign: 'center',
                                            border: '1px solid #0F75BC',
                                            p: 3,
                                            borderRadius: '10px',
                                            flexDirection: 'row',
                                            display: 'flex',
                                            alignItems: 'center',
                                            boxShadow: '6px 14px 21px #d1d1d1;'
                                        }}
                                    >
                                        <span style={{ width: '100%', maxWidth: '50px', display: 'block' }}>
                                            <img src={warning} alt="warning" width="100%" />
                                        </span>
                                        <Typography
                                            color={theme.palette.primary.black}
                                            sx={{ fontSize: '20px', fontWeight: 'normal', display: 'flex', alignItems: 'center', pl: 2 }}
                                        >
                                            Sorry we could not find the page you were looking for.
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={1} sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
                                        <Typography>
                                            <Button
                                                variant="contained"
                                                style={boxStyle}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                Go Back
                                            </Button>
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Somethingwrong;
