import { Button, Card, Chip, Grid, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/styles';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useState } from 'react';

const ExerciseActivitiesxpansionCard = () => {
    const theme = useTheme();
    const [value, setValue] = useState(new Date());
    const [dvalue, setDValue] = useState('02/09/2021');

    return (
        <>
            <Grid item xs={12}>
                <Card sx={{ border: '1px solid #e9e8e8', p: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-select-experience1"
                                select
                                // label="Select From List"
                                defaultValue="Moderate"
                                size="small"
                                fullWidth
                            >
                                <MenuItem selected value="Moderate">
                                    Moderate
                                </MenuItem>
                                <MenuItem value="Slow">Slow</MenuItem>
                                <MenuItem value="Fast">Fast</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="inputtext" label="Enter Minutes" size="small" fullWidth>
                                Enter Minutes
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ pt: 2 }}>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-select-experience1"
                                select
                                label="Frequency"
                                defaultValue="Custom"
                                size="small"
                                fullWidth
                            >
                                <MenuItem selected value="Custom">
                                    Custom
                                </MenuItem>
                                {/* <MenuItem value="Week">Week</MenuItem> */}
                                <MenuItem value="Daily">Daily</MenuItem>
                                <MenuItem value="Weekly">Weekly</MenuItem>
                                <MenuItem value="Monthly">Monthly</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label=""
                                    value={dvalue}
                                    size="large"
                                    fullWidth
                                    onChange={(newValue) => {
                                        setDValue(newValue);
                                    }}
                                    InputProps={{
                                        style: {
                                            height: 40
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label=""
                                    value={value}
                                    size="small"
                                    fullWidth
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    InputProps={{
                                        style: {
                                            height: 40
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
};

export default ExerciseActivitiesxpansionCard;
