import { useTheme, Grid } from '@mui/material';
import Goals from './goals/Goals';

const PlansTab = () => {
    const theme = useTheme();
    // const location = useLocation();
    // const from = location.state;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }}>
                    <Goals />
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }}>
                    <GoalsCard />
                </Grid> */}

                {/* {from === 'diet' && (
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }}>
                        <DietCard />
                    </Grid>
                )} */}
            </Grid>
        </>
    );
};

export default PlansTab;
