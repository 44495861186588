import PropTypes from 'prop-types';

// material-ui
import { Grid, IconButton, Stack, Typography } from '@mui/material';

// third-party
import { format } from 'date-fns';

// assets
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

// ==============================|| CALENDAR TOOLBAR ||============================== //

const Toolbar = ({ date, onClickNext, onClickPrev, ...others }) => (
    <Grid alignItems="center" container justifyContent="space-between" spacing={3} {...others} sx={{ pb: 3 }}>
        <Grid item xs={4} lg={4} md={4} />
        <Grid item xs={4} lg={4} md={4}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <IconButton onClick={onClickPrev} size="large">
                    <IconChevronLeft />
                </IconButton>
                <Typography variant="h3" color="textPrimary">
                    {format(date, 'MMMM yyyy')}
                </Typography>
                <IconButton onClick={onClickNext} size="large">
                    <IconChevronRight />
                </IconButton>
            </Stack>
        </Grid>
        <Grid item xs={4} lg={4} md={4} />
    </Grid>
);

Toolbar.propTypes = {
    date: PropTypes.object,
    onClickNext: PropTypes.func,
    onClickPrev: PropTypes.func
};

export default Toolbar;
