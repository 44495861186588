import { useTheme, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Calendar from './calendar';

const data = [
    { time: '9:00 AM', title: 'Breakfast', food: 'Yogurt, Berries, Granola', status: 'completed' },
    { time: '12:00 PM', title: 'Lunch', food: '4 oz Chicken, Salad', status: 'pending' },
    { time: '5:30 PM', title: 'Snacks', food: 'Peanut Pancakes', status: 'pending' },
    { time: '7:30 PM', title: 'Dinner', food: '1 Cup Oats', status: 'pending' }
];

const MonthlyMealPlan = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <Calendar />
                </Grid>
            </Grid>
        </>
    );
};

export default MonthlyMealPlan;
