import services from 'utils/mockAdapter';
import { add, set, sub } from 'date-fns';
import value from 'assets/scss/_themes-vars.module.scss';

const dataDate = new Date('05/05/2022');
const events = [
    {
        id: '5e88830672d089c53c46ece1',
        allDay: false,
        color: value.errorMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate - 1, { hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece2',
        allDay: false,
        color: value.successDark,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate - 1, { hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece3',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate - 1, { hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece4',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate - 1, { hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece6',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate - 1, { hours: 22, minutes: 0 }),
        title: 'Bed Time'
    },
    {
        id: '5e88830672d089c53c46ece7',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate, { hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece8',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate, { hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece9',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate, { hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece10',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: set(dataDate, { hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece12',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 1, hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece13',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 1, hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece14',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 1, hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece15',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 1, hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece17',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 2, hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece2',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 2, hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece3',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 2, hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece4',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 2, hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece1',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 3, hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece2',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 3, hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece3',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 3, hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece4',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 3, hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece1',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 4, hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece2',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 4, hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece3',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 4, hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece4',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 4, hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece1',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 5, hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece2',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 5, hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece3',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 5, hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece4',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 5, hours: 17, minutes: 0 }),
        title: 'Snacks'
    },
    {
        id: '5e88830672d089c53c46ece1',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 6, hours: 7, minutes: 0 }),
        title: 'Breakfast'
    },
    {
        id: '5e88830672d089c53c46ece2',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 6, hours: 12, minutes: 0 }),
        title: 'Lunch'
    },
    {
        id: '5e88830672d089c53c46ece3',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 6, hours: 19, minutes: 0 }),
        title: 'Dinner'
    },
    {
        id: '5e88830672d089c53c46ece4',
        allDay: false,
        color: value.primaryMain,
        description: 'Get a new quote for the payment processor',
        start: add(dataDate, { days: 6, hours: 17, minutes: 0 }),
        title: 'Snacks'
    }
];

services.onGet('/api/calendar/events').reply(() => [
    200,
    {
        events
    }
]);
