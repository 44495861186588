import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery, Grid } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

import { ReactComponent as SutterHealth } from '../../assets/images/logos/sutter_health_logo.svg';

// assets
import { IconChevronRight } from '@tabler/icons';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // styles
    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter
            }),
            [theme.breakpoints.up('md')]: {
                // marginLeft: -(drawerWidth - 20),
                // marginLeft: -drawerWidth,
                marginLeft: '0',
                width: `calc(100% - ${drawerWidth}px)`
            },
            [theme.breakpoints.down('md')]: {
                // marginLeft: -drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`
                // padding: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                // marginLeft: -drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                marginTop: '0 px'
                // padding: '16px'
                // marginRight: '10px'
            }
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: matchDownMd ? -drawerWidth : 0,
            marginTop: '80px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            background: 'transparent linear-gradient(180deg, #E6EAF9 0%, #FEFDFA 50%, #EBEFF9 100%) 0% 0% no-repeat',
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                // marginLeft: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                // marginLeft: '10px'
            }
        })
    }));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}

            {/* drawer */}
            {!matchDownMd && <Sidebar />}
            {matchDownMd && drawerOpen && <Sidebar />}
            {/* main content */}
            <Main theme={theme} open={drawerOpen}>
                {/* breadcrumb */}
                {!matchDownMd && (
                    <AppBar
                        enableColorOnDark
                        position="absolute"
                        color="inherit"
                        elevation={0}
                        sx={{
                            background: 'transparent linear-gradient(180deg, #E6EAF9 0%, #FEFDFA 50%, #e6eaf9 100%) 0% 0% no-repeat',
                            transition: drawerOpen ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        {header}
                    </AppBar>
                )}
                {matchDownMd && (
                    <AppBar
                        enableColorOnDark
                        position="absolute"
                        color="inherit"
                        elevation={0}
                        sx={{
                            background: 'transparent linear-gradient(180deg, #E6EAF9 0%, #FEFDFA 50%, #e6eaf9 100%) 0% 0% no-repeat',
                            transition: drawerOpen ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        {header}
                    </AppBar>
                )}
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                        <Outlet />
                    </>
                )}
            </Main>
        </Box>
    );
};

export default MainLayout;
