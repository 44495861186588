import { Grid } from '@mui/material';
import AuthSlider from 'ui-component/cards/AuthSlider';
import imgMain from './careimage-background.svg';

const items = [
    {
        title: 'Lorum Upsum Dummy Title Here',
        description: 'Lorum Upsum Dummy sub-title Here'
    },
    {
        title: 'Lorum Upsum Dummy Title Here',
        description: 'Lorum Upsum Dummy sub-title Here'
    },
    {
        title: 'Lorum Upsum Dummy Title Here',
        description: 'Lorum Upsum Dummy sub-title Here'
    }
];
const PatientLoginCardRight = () => (
    <Grid item container justifyContent="center">
        <Grid item xs={12}>
            <Grid item container justifyContent="center" sx={{ pb: 8 }}>
                <Grid item xs={10} lg={8} sx={{ '& .slick-list': { pb: 2 } }}>
                    <AuthSlider items={items} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ position: 'relative' }}>
            <img
                alt="Auth method"
                src={imgMain}
                style={{
                    maxWidth: '100%',
                    margin: '0 auto',
                    display: 'block',
                    width: 300,
                    position: 'relative',
                    zIndex: 5
                }}
            />
        </Grid>
    </Grid>
);

export default PatientLoginCardRight;
