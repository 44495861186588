import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { gridSpacing } from 'store/constant';

const AddActivityCard = () => {
    const theme = useTheme();
    const [enddate, setEndDate] = useState(null);

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
                <SubCard title="Add Activity" sx={cardStyle}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField fullWidth id="name" label="Name" size="small" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-select-experience1"
                                    select
                                    label="Frequency"
                                    defaultValue="Daily"
                                    size="small"
                                    fullWidth
                                >
                                    <MenuItem selected value="Daily">
                                        Daily
                                    </MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Custom">Custom</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Activity End Date"
                                        value={enddate}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }} align="left">
                        <Button variant="contained" size="large" sx={{ mr: 1 }}>
                            <Typography>Save</Typography>
                        </Button>
                        <Button variant="outlined" size="large">
                            <Typography>Cancel</Typography>
                        </Button>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default AddActivityCard;
