import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    styled,
    tableCellClasses,
    Button,
    MenuItem,
    Menu,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// table data
function createData(status, goal, startDate, targetDate, goalTarget, subValue) {
    return {
        status,
        goal,
        startDate,
        targetDate,
        goalTarget,
        subValue
    };
}

function Row({ row, key }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FiberManualRecordIcon sx={{ color: row.status === '1' ? 'success.main' : 'error.main' }} />
                        <b>{row.goal}</b>
                    </Stack>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.startDate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.targetDate}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.goalTarget}</b>
                    <br />
                    {row.subValue ? (
                        <Typography variant="h6">
                            Protein - <b>{row.subValue[0]}</b> | Carb - <b>{row.subValue[1]}</b>
                            <br />
                            Sodium - <b>{row.subValue[2]}</b>
                            <br />
                            Cholestrol - <b>{row.subValue[3]}</b>
                            <br />
                            Sugar - <b>{row.subValue[4]}</b> | Fat - <b>{row.subValue[5]}</b>
                            <br />
                            Fiber - <b>{row.subValue[6]}</b>
                            <br />
                            Supplement - <b>{row.subValue[6]}</b>
                        </Typography>
                    ) : (
                        <></>
                    )}
                </StyledTableCell>
                <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                        <Button aria-label="expand row" size="small" variant="text">
                            View Progress
                        </Button>
                        <IconButton size="small" sx={{ mt: -0.75, mr: -0.75 }}>
                            {/* onClick={handleClick} */}
                            <MoreHorizOutlinedIcon />
                        </IconButton>
                        <Menu
                            id={key}
                            // getContentAnchorEl={null}
                            anchorEl={anchorEl}
                            keepMounted
                            // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Edit</MenuItem>
                            <MenuItem onClick={handleClose}>Delete</MenuItem>
                        </Menu>
                    </Stack>
                </StyledTableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('1', 'Weight', '05/01/2022', '05/22/2022', '150 lbs'),
    createData('2', 'Nutrition', '05/01/2022', '05/22/2022', '250 cals', ['15%', '5%', '20%', '10%', '5%', '5%', '18%', '22%'])
];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function GoalsTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Goal</StyledTableCell>
                            <StyledTableCell>Start Date</StyledTableCell>
                            <StyledTableCell>Target Date</StyledTableCell>
                            <StyledTableCell>Goal Target</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
