import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { gridSpacing } from 'store/constant';
import AddActivityCard from '../AddActivityCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';

const MedicationActivity = ({ back }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [addactivity, setAddActivity] = useState(false);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} lg={12} md={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{
                            ml: '20px !important',
                            mt: '20px !important',
                            p: '24px !important',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: theme.palette.common.grey
                        }}
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton onClick={() => back()}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <Typography variant="h3">Medication Plan</Typography>
                            </Stack>
                        </Grid>
                        <AddActivityCard />
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }} align="left">
                            <Button variant="outlined" size="small" onClick={() => setAddActivity(!addactivity)}>
                                <AddCircleIcon />
                                &nbsp;
                                <Typography>Add Activity</Typography>
                            </Button>
                        </Grid>
                        {addactivity && (
                            <Grid item xs={12} lg={12} md={12}>
                                <AddActivityCard />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MedicationActivity;
