import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
// Material-UI imports
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// Project imports
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/slices/menu';

// Assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const dispatch = useDispatch();
    const { openItem } = useSelector((state) => state.menu);

    const Icon = item.icon;

    // Choose an icon component based on whether item has an icon
    const itemIcon = item.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: openItem.findIndex((id) => id === item.id) > -1 ? 8 : 6,
                height: openItem.findIndex((id) => id === item.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    // Create a link component if the item is external
    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={item.target ? '_blank' : '_self'} />)
    };

    if (item.external) {
        listItemProps = { component: 'a', href: item.url, target: item.target ? '_blank' : '_self' };
    }

    const itemHandler = (id) => {
        dispatch(activeItem([id]));
        dispatch(openDrawer(true));
    };

    return (
        <ListItemButton
            disabled={item.active}
            {...listItemProps}
            onClick={() => itemHandler(item.id)}
            selected={openItem.findIndex((id) => id === item.id) > -1}
            sx={{
                mb: 0.5,
                py: 1.25,
                borderLeft: '8px solid transparent',
                '&.Mui-selected': {
                    borderLeft: '8px solid blue'
                }
            }}
        >
            {/* Display the icon and title in one line */}
            <ListItemIcon
                sx={{
                    minWidth: 36
                }}
            >
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography sx={{ textTransform: 'uppercase', fontSize: '0.87rem' }} color="inherit">
                        <b>{item.title}</b>
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object.isRequired,
    level: PropTypes.number
};

export default NavItem;
