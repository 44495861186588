// material-ui
import { useTheme } from '@emotion/react';
import { Grid, TextField, Button, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';

// third-party
import { useIntl, FormattedMessage } from 'react-intl';

const ChangeCredential = () => {
    const theme = useTheme();
    const intl = useIntl();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <div>
                    <Grid container direction="column" spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <SubCard title={intl.formatMessage({ id: 'Change_Username' })} sx={cardStyle}>
                                <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="password"
                                            id="newUsername"
                                            fullWidth
                                            label={intl.formatMessage({ id: 'New_Username' })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="password"
                                            id="currentPassword"
                                            fullWidth
                                            label={intl.formatMessage({ id: 'Current_Password' })}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button variant="contained">
                                                <FormattedMessage id="Update" />
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            <SubCard title="Change Password" sx={cardStyle}>
                                <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="password"
                                            id="currentPassword"
                                            fullWidth
                                            label={intl.formatMessage({ id: 'Current_Password' })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="password"
                                            id="newPassword"
                                            fullWidth
                                            label={intl.formatMessage({ id: 'New_Password' })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="password"
                                            id="reEnterNewPassword"
                                            fullWidth
                                            label={intl.formatMessage({ id: 'Re_Enter_New_password' })}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button variant="contained">
                                                <FormattedMessage id="Update" />
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </SubCard>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default ChangeCredential;
