import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const FilterTable = () => {
    const [name, setName] = useState(''); // Initialize with empty string or desired default value

    const handleChange = (event) => {
        setName(event.target.value);
    };

    return (
        <div>
            <FormControl sx={{ m: 2, minWidth: 170 }}>
                <Select value={name} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="">Search by Name</MenuItem>
                    <MenuItem value={10}>Search by Date</MenuItem>
                    <MenuItem value={20}>Search by Rating</MenuItem>
                    <MenuItem value={30}>Search by Unread</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default FilterTable;
