// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import ForwardTwoToneIcon from '@mui/icons-material/ForwardTwoTone';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';
import ReplyTwoToneIcon from '@mui/icons-material/ReplyTwoTone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, CardContent, Collapse, Grid, IconButton, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
// project imports
import useConfig from 'hooks/useConfig';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import gfm from 'remark-gfm';
import { gridSpacing } from 'store/constant';
import AttachmentCard from 'ui-component/cards/AttachmentCard';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import Avatar from 'ui-component/extended/Avatar';

const avatarImage = require.context('assets/images/careteam', true);

// ==============================|| MAIL DETAILS ||============================== //

const MessageDetails = ({ handleUserDetails, data, handleStarredChange, handleImportantChange }) => {
    const theme = useTheme();
    const { fontFamily } = useConfig();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickSort = (event) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleCloseSort = () => {
        setAnchorEl(null);
    };

    const [openQuill, setOpenQuill] = useState(false);
    const handleChangeQuill = () => {
        setOpenQuill(true);
    };

    return (
        <MainCard
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
            }}
            content={false}
        >
            <CardContent>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={matchDownSM ? 1 : 0}>
                            <Grid item>
                                <Stack direction="row" alignItems="center" spacing={matchDownSM ? 1 : 2}>
                                    <IconButton onClick={(e) => handleUserDetails(e, null)} size="small">
                                        <KeyboardArrowLeftTwoToneIcon />
                                    </IconButton>
                                    <Avatar
                                        alt={data?.profile.name}
                                        src={data?.profile && data.profile.avatar && avatarImage(`./${data.sender.avatar}`).default}
                                        size={matchDownSM ? 'xs' : 'sm'}
                                    />
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            <Stack
                                                direction={matchDownSM ? 'column' : 'row'}
                                                alignItems={matchDownSM ? 'flex-start' : 'center'}
                                                spacing={matchDownSM ? 0 : 1}
                                            >
                                                <Typography variant={matchDownSM ? 'h5' : 'h4'}>{data?.sender.name}</Typography>
                                                <Typography sx={{ display: { xs: 'block', sm: 'none' } }} variant="subtitle2">
                                                    From: &lt;{data?.profile.to}&gt;
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            <Typography variant="subtitle2">From: &lt;{data?.profile.to}&gt;</Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item>
                                {/* <Typography variant="subtitle2">{format(new Date(data?.time), 'd MMM yy HH:mm a')}</Typography> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <PerfectScrollbar style={{ height: 'calc(100vh - 275px)', minHeight: 525 }}>
                <CardContent sx={{ pt: 0 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" spacing={0}>
                                        <Grid item>
                                            <Typography variant={matchDownSM ? 'h4' : 'h3'}>{data?.subject}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth />
                                        <Grid item>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseSort}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                            >
                                                <MenuItem onClick={handleCloseSort}>Name</MenuItem>
                                                <MenuItem onClick={handleCloseSort}>Date</MenuItem>
                                                <MenuItem onClick={handleCloseSort}>Ratting</MenuItem>
                                                <MenuItem onClick={handleCloseSort}>Unread</MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Dear {data?.profile.name},</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                '& > p': {
                                                    ...theme.typography.body1,
                                                    marginBottom: 0
                                                }
                                            }}
                                        >
                                            <ReactMarkdown remarkPlugins={[gfm]}>{data?.message}</ReactMarkdown>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Kind Regards,</Typography>
                                            <Typography variant="body2">{data?.sender.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {data?.attachments && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <AttachmentTwoToneIcon fontSize="small" />
                                                    <Typography variant="h5">{data?.attachments.length} </Typography>
                                                    <Typography variant="h5">Attachments</Typography>
                                                </Stack>
                                            </Grid>
                                            {data?.attachments.map((item, index) => (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                                    <AttachmentCard image={item.image} title={item.title} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Button variant="outlined" startIcon={<ReplyTwoToneIcon />} onClick={handleChangeQuill}>
                                                Reply
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" startIcon={<ForwardTwoToneIcon />} onClick={handleChangeQuill}>
                                                Forward
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Collapse in={openQuill} sx={{ width: '100%' }}>
                                    {openQuill && (
                                        <Grid item xs={12} sx={{ p: 3 }}>
                                            <SubCard
                                                sx={{
                                                    bgcolor:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.dark
                                                            : theme.palette.primary[200] + 40,
                                                    '& .quill': {
                                                        bgcolor:
                                                            theme.palette.mode === 'dark'
                                                                ? theme.palette.dark.main
                                                                : theme.palette.grey[50],
                                                        borderRadius: '12px',
                                                        '& .ql-toolbar': {
                                                            bgcolor:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.light
                                                                    : theme.palette.grey[100],
                                                            borderColor:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.light + 20
                                                                    : theme.palette.primary.light,
                                                            borderTopLeftRadius: '12px',
                                                            borderTopRightRadius: '12px'
                                                        },
                                                        '& .ql-container': {
                                                            fontFamily,
                                                            fontSize: '0.875rem',
                                                            borderColor:
                                                                theme.palette.mode === 'dark'
                                                                    ? `${theme.palette.dark.light + 20} !important`
                                                                    : theme.palette.primary.light,
                                                            borderBottomLeftRadius: '12px',
                                                            borderBottomRightRadius: '12px',
                                                            '& .ql-editor': {
                                                                minHeight: 125
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                <ReactQuill theme="snow" />
                                                <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
                                                    <Grid item>
                                                        <IconButton size="large">
                                                            <UploadFileIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton size="large">
                                                            <AttachmentTwoToneIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item sx={{ flexGrow: 1 }} />
                                                    <Grid item>
                                                        <Button onClick={() => setOpenQuill(false)} sx={{ color: theme.palette.grey[900] }}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="contained">Reply</Button>
                                                    </Grid>
                                                </Grid>
                                            </SubCard>
                                        </Grid>
                                    )}
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </PerfectScrollbar>
        </MainCard>
    );
};

MessageDetails.propTypes = {
    data: PropTypes.object,
    handleUserDetails: PropTypes.func,
    handleStarredChange: PropTypes.func,
    handleImportantChange: PropTypes.func
};

export default MessageDetails;
