import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import SendIcon from '@mui/icons-material/Send';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import FileUpload from './fileupload';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import axios from 'axios';
import {
    Autocomplete,
    Avatar,
    Collapse,
    Dialog,
    DialogContent,
    Grid,
    Slide,
    TextField,
    useMediaQuery,
    FormControlLabel,
    Checkbox,
    Stack,
    Button,
    Typography,
    List,
    ListItem,
    IconButton,
    Box
} from '@mui/material';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
// import { Box } from '@mui/system';
import { IconArrowsDiagonal2 } from '@tabler/icons';
import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// third-party
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
// project imports
import { gridSpacing } from 'store/constant';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const avatarImage = require.context('assets/images/careteam', true);

// ==============================|| MAIL COMPOSE DIALOG ||============================== //
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

const ComposeDialog = () => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const mailState = useSelector((state) => state.mail);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const [ccBccValue, setCcBccValue] = useState(false);
    const handleCcBccChange = () => {
        setCcBccValue((prev) => !prev);
    };

    let composePosition = {};
    useEffect(() => {
        setData(mailState.mails);
    }, [mailState]);
    const [position, setPosition] = useState(false);
    if (!position) {
        composePosition = {
            '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                '& .MuiPaper-root': { mb: 0, borderRadius: '12px 12px 0px 0px', maxWidth: 595 }
            }
        };
    }
    const intl = useIntl();

    const topics = [{ topic: 'Diet nutrition guidlines' }, { topic: 'Tips for better diet' }, { topic: 'Planning sleep and rest' }];
    const recipients = [{ name: 'Robert Hailey', designation: 'PID: 546789890 | DOB: 01-01-1990', avatar: 'robert_hailey_patient.png' }];
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => {
            const combinedFiles = [...prevFiles, ...newFiles];
            const uniqueFiles = Array.from(new Set(combinedFiles.map((file) => file.name))).map((name) =>
                combinedFiles.find((file) => file.name === name)
            );
            return uniqueFiles;
        });
    };

    const handleRemoveFile = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        axios
            .post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log('Files uploaded successfully:', response.data);
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
            });
    };
    return (
        <>
            <Button variant="contained" onClick={handleClickOpen} sx={{ width: '100%' }} size="large">
                <FormattedMessage id="Compose" />
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                sx={composePosition}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        maxHeight: '90vh', // Ensures the dialog doesn't exceed 90% of the viewport height
                        height: 'auto'
                    }
                }}
            >
                {open && (
                    <DialogContent sx={{ p: 0 }}>
                        <Grid item xs={12} sx={{ background: '#f0f0f0', padding: '10px 20px', position: 'relative', zIndex: 99 }}>
                            <Grid container alignItems="center" spacing={0}>
                                <Grid item sm zeroMinWidth>
                                    <Typography component="div" align="left" variant="h4">
                                        <FormattedMessage id="New_Message" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => setPosition(!position)} size="large">
                                        <IconArrowsDiagonal2 />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleCloseDialog} size="large">
                                        <HighlightOffTwoToneIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sx={{ maxHeight: '510px', overflowY: 'auto' }}>
                            <Grid container spacing={gridSpacing} sx={{ padding: '25px 20px' }}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={topics}
                                        getOptionLabel={(option) => option.topic}
                                        renderInput={(params) => <TextField {...params} label={intl.formatMessage({ id: 'Topic' })} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox defaultChecked name="checkedMobile" color="primary" />}
                                                label="Patient"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox name="checkedMobile" color="primary" />}
                                                label="Provider User"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="assign"
                                                options={recipients}
                                                fullWidth
                                                autoHighlight
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
                                                        {option.name !== 'Family Care Team' ? (
                                                            // <img
                                                            //     loading="lazy"
                                                            //     sx={{ borderRadius: '0' }}
                                                            //     width="20"
                                                            //     src={avatarImage(`./${option.avatar}`).default}
                                                            //     alt=""
                                                            // />
                                                            <Avatar
                                                                alt={option.name}
                                                                src={option.avatar && avatarImage(`./${option.avatar}`).default}
                                                                size={matchDownSM ? 'xs' : 'sm'}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                color="primary"
                                                                sx={{
                                                                    width: 38,
                                                                    height: 38,
                                                                    fontSize: '1rem',
                                                                    bgcolor: theme.palette.primary.paper
                                                                }}
                                                            >
                                                                FC
                                                            </Avatar>
                                                        )}
                                                        <Typography m={1} variant="h5">
                                                            {option.name}
                                                        </Typography>
                                                        - {option.designation}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // name="To"
                                                        label={intl.formatMessage({ id: 'To' })}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password' // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label={intl.formatMessage({ id: 'Subject' })} />
                                </Grid>
                                <Grid item xs={12} sx={{ display: ccBccValue ? 'block' : 'none' }}>
                                    <Collapse in={ccBccValue}>
                                        {ccBccValue && (
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: 'CC' })} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: 'BCC' })} />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Collapse>
                                </Grid>

                                {/* quill editor */}
                                <Grid item xs={12}>
                                    <Box>
                                        <Stack
                                            sx={{
                                                '& .quill': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                                                    borderRadius: '12px',
                                                    '& .ql-toolbar': {
                                                        bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
                                                        borderColor:
                                                            theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'grey.400',
                                                        borderTopLeftRadius: '12px',
                                                        borderTopRightRadius: '12px'
                                                    },
                                                    '& .ql-container': {
                                                        height: 80, // Fixed height for the editor
                                                        overflowY: 'auto', // Enable vertical scrolling
                                                        borderColor:
                                                            theme.palette.mode === 'dark'
                                                                ? `${theme.palette.dark.light + 20} !important`
                                                                : `${theme.palette.grey[400]} !important`,
                                                        borderBottomLeftRadius: '12px',
                                                        borderBottomRightRadius: '12px',
                                                        '& .ql-editor': {
                                                            height: '100%'
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <ReactQuill theme="snow" />
                                        </Stack>
                                    </Box>
                                    <Stack direction="row" spacing={2} sx={{ padding: '5px 0px 5px 0' }}>
                                        <div style={{ marginTop: '5px' }}>
                                            <Typography variant="h6">Selected Files:</Typography>
                                            <List sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {files.length > 0 &&
                                                    files.map((file, index) => (
                                                        <ListItem
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '200px',
                                                                border: '1px solid #d1d1d1',
                                                                borderRadius: '8px',
                                                                margin: '0 10px 10px 0',
                                                                alignItems: 'center'
                                                            }}
                                                            secondaryAction={
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                    onClick={() => handleRemoveFile(file.name)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    maxWidth: '200px',
                                                                    display: 'inline-block',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                {file.name}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                            </List>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ background: '#f0f0f0', padding: '10px 20px', position: 'relative', zIndex: 99 }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Button variant="contained">
                                        <SendIcon />
                                        <FormattedMessage id="Send" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={2} sx={{ padding: '10px 0px 10px 0' }}>
                                        <label htmlFor="file-upload">
                                            <Box display="inline-block">
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{
                                                        border: '1px solid #1d5ddf',
                                                        padding: '6px 12px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '4px',
                                                        color: '#1d5ddf'
                                                    }}
                                                >
                                                    <AttachFileRoundedIcon />
                                                    <Typography variant="p" sx={{ marginLeft: '0px !important' }}>
                                                        Browse Files
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                        </label>
                                    </Stack>
                                </Grid>
                                <Grid item sx={{ flexGrow: 1 }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
};

export default ComposeDialog;
