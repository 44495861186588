import {
    Grid,
    Typography,
    Card,
    CardContent,
    useTheme,
    Button,
    FormControlLabel,
    TextField,
    MenuItem,
    Stack,
    Checkbox,
    IconButton,
    Chip
} from '@mui/material';
// import { ReactComponent as MealGoal } from '../../../../assets/images/icons/mealplan.svg';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { gridSpacing } from 'store/constant';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ExerciseActivitiesxpansionCard from './ExerciseActivitiesExpansionCard';
import AddActivityCard from '../AddActivityCard';

const ExerciseActivities = ({ back }) => {
    const theme = useTheme();

    // const menuItems = {
    //     items: [
    //         {
    //             id: 'sample-docs-roadmap',
    //             type: 'group',
    //             children: [
    //                 {
    //                     id: 'mygoals',
    //                     title: 'My Goals & Activities',
    //                     type: 'collapse',
    //                     url: '/dreams/mygoals',
    //                     breadcrumbs: true,
    //                     children: [
    //                         {
    //                             id: 'exercise',
    //                             title: 'Exercise',
    //                             type: 'item',
    //                             url: '/dreams/mygoals/exercise',
    //                             breadcrumbs: true
    //                         }
    //                     ]
    //                 }
    //             ]
    //         }
    //     ]
    // };
    const [addactivity, setAddActivity] = useState(false);
    const [enddate, setEndDate] = useState(null);

    // const [value, setValue] = useState('08:00 am');
    const [wchecked, setWChecked] = useState(false);
    const [rchecked, setRChecked] = useState(false);
    const [schecked, setSChecked] = useState(false);
    const [bchecked, setBChecked] = useState(false);
    const [cchecked, setCChecked] = useState(false);
    const [ochecked, setOChecked] = useState(false);

    const handleWalkingChange = (event) => {
        setWChecked(!wchecked);
    };

    const handleCyclingChange = (event) => {
        setBChecked(!bchecked);
    };

    const handleCardioChange = (event) => {
        setCChecked(!cchecked);
    };
    const handleSwimmingChange = (event) => {
        setSChecked(!schecked);
    };
    const handleRunningChange = (event) => {
        setRChecked(!rchecked);
    };

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    return (
        <>
            {/* <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}> */}
            <Grid container spacing={gridSpacing}>
                {/* <Grid item xs={12} lg={12} md={12}>
                            <DREAMSBreadCrumbs
                                navigation={menuItems}
                                home="/dreams"
                                homeTitle="DREAMS"
                                separator={NavigateNextIcon}
                                sx={{
                                    mb: '0px !important',
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                                }}
                            />
                        </Grid> */}
                {/* <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton onClick={() => back()}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <Typography variant="h3">Exercise</Typography>
                            </Stack>
                        </Grid> */}
                <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sx={{
                        ml: '20px !important',
                        mt: '20px !important',
                        p: '24px !important',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: theme.palette.common.grey
                        // borderColor: theme.palette.primary.main
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12}>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 1 }}>
                                <IconButton onClick={() => back()}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <Typography variant="h3">Exercise Plan</Typography>
                            </Stack>
                            <Card sx={{ border: '1px solid', borderColor: '#e9e8e8' }}>
                                <CardContent sx={cardStyle}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={wchecked} onChange={handleWalkingChange} />}
                                                label="Walking"
                                            />
                                        </Grid>
                                        {wchecked && <ExerciseActivitiesxpansionCard />}

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={rchecked} onChange={handleRunningChange} />}
                                                label="Running"
                                            />
                                        </Grid>
                                        {rchecked && <ExerciseActivitiesxpansionCard />}
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={schecked} onChange={handleSwimmingChange} />}
                                                label="Swimming"
                                            />
                                        </Grid>
                                        {schecked && <ExerciseActivitiesxpansionCard />}
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={bchecked} onChange={handleCyclingChange} />}
                                                label="Bicycling"
                                            />
                                        </Grid>
                                        {bchecked && <ExerciseActivitiesxpansionCard />}

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={cchecked} onChange={handleCardioChange} />}
                                                label="Cardio/Resistance"
                                            />
                                        </Grid>
                                        {cchecked && <ExerciseActivitiesxpansionCard />}
                                        {/* <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={ochecked} onChange={handleOthersChange} />}
                                                        label="Others"
                                                    />
                                                </Grid> */}
                                        {/* {ochecked && <ExerciseActivitiesxpansionCard />} */}
                                        <Grid item xs={5}>
                                            <TextField
                                                id="outlined-select-experience1"
                                                select
                                                // label="Select From List"
                                                size="small"
                                                defaultValue="Choose From List"
                                                fullWidth
                                            >
                                                <MenuItem selected value="Choose From List">
                                                    Choose From List
                                                </MenuItem>
                                                <MenuItem value="jogging">Jogging</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }} align="left">
                                            <Button variant="contained" size="large" sx={{ mr: 1 }}>
                                                <Typography>Save</Typography>
                                            </Button>
                                            <Button variant="outlined" size="large">
                                                <Typography>Cancel</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} lg={12} md={12}>
                            <Grid container>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="h3" sx={{ pb: 2 }}>
                                        Track Blood Pressure
                                    </Typography>

                                    <Chip
                                        label="Monitored by Dr. Roger Miles"
                                        size="small"
                                        sx={{
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'primary.paper',
                                            color: 'primary.dark'
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Card sx={{ border: '1px solid', borderColor: '#e9e8e8' }}>
                                <CardContent sx={cardStyle}>
                                    <Grid container spacing={1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="outlined-select-experience1"
                                                    select
                                                    label="Frequency"
                                                    defaultValue="Daily"
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <MenuItem selected value="Daily">
                                                        Daily
                                                    </MenuItem>
                                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                                    <MenuItem value="Custom">Custom</MenuItem>
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        renderInput={(props) => (
                                                            <TextField size="small" fullWidth {...props} helperText="" />
                                                        )}
                                                        label="Activity End Date"
                                                        value={enddate}
                                                        onChange={(newValue) => {
                                                            setEndDate(newValue);
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }} align="left">
                                            <Button variant="contained" size="large" sx={{ mr: 1 }}>
                                                <Typography>Save</Typography>
                                            </Button>
                                            <Button variant="outlined" size="large">
                                                <Typography>Cancel</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Grid item xs={12} lg={12} md={12} sx={{ mt: 2 }} align="left">
                                <Button variant="outlined" size="small" onClick={() => setAddActivity(!addactivity)}>
                                    <AddCircleIcon />
                                    &nbsp;
                                    <Typography>Add Activity</Typography>
                                </Button>
                            </Grid>
                            {addactivity && (
                                <Grid item xs={12} lg={12} md={12}>
                                    <AddActivityCard />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12} lg={12} md={12}>
                            <MyCareTeam />
                        </Grid> */}
            </Grid>
            {/* </Grid> */}
            {/* <Grid item xs={12} lg={4} md={4}>
                    <MyGoalsSidePanel />
                </Grid> */}
            {/* </Grid> */}
        </>
    );
};

export default ExerciseActivities;
