// material-ui
import { Grid, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';

// project imports

// ==============================|| PROFILE ||============================== //

const ViewNotes = () => {
    const theme = useTheme();

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12}>
                Notes
            </Grid>
        </Grid>
    );
};

export default ViewNotes;
