import { useEffect, useRef, useState } from 'react';

// material-ui
import { useMediaQuery, Grid, Typography } from '@mui/material';

// third-party
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';

// redux
import { useDispatch, useSelector } from 'store';
import { getEvents, updateEvent } from 'store/slices/calendar';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';
import value from 'assets/scss/_themes-vars.module.scss';
import { add, set, sub } from 'date-fns';
import CustomMealModal from '../../today/CustomMealModal';
import CreateMealPlan from '../../createmealplan';

// assets

// ==============================|| APPLICATION CALENDAR ||============================== //

const Calendar = () => {
    const [date, setDate] = useState(new Date('05/05/2022'));
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const calendarRef = useRef(null);
    const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // fetch events data
    const [events, setEvents] = useState([]);
    const calendarState = useSelector((state) => state.calendar);
    const [openEvent, setOpenEvent] = useState(false);
    const [showCreateMealPlanContent, setShowCreateMealPlanContent] = useState(false);
    const navigateToCreateMealPlan = () => setShowCreateMealPlanContent(true);
    const returnToCalendarView = () => setShowCreateMealPlanContent(false);
    const handleOpenEvent = () => setOpenEvent(true);
    const handleCloseEvent = () => setOpenEvent(false);
    useEffect(() => {
        dispatch(getEvents());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setEvents(calendarState.events);
    }, [calendarState]);

    const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

    // calendar toolbar events
    const handleDateToday = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    // calendar event select/add/edit/delete
    const handleRangeSelect = (arg) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.unselect();
        }

        setSelectedRange({
            start: arg.start,
            end: arg.end
        });
        navigateToCreateMealPlan();
    };

    const handleEventSelect = (arg) => {
        if (arg.event.id) {
            const selectEvent = events.find((_event) => _event.id === arg.event.id);
            setSelectedEvent(selectEvent);
            setData(selectEvent);
            handleOpenEvent();
        } else {
            setSelectedEvent(null);
        }
    };

    const handleEventUpdate = async ({ event }) => {
        try {
            dispatch(
                updateEvent({
                    eventId: event.id,
                    update: {
                        allDay: event.allDay,
                        start: event.start,
                        end: event.end
                    }
                })
            );
        } catch (err) {
            console.error(err);
        }
    };

    const eventTimeFormat = {
        // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };

    return (
        <>
            {!showCreateMealPlanContent && (
                <>
                    <CalendarStyled>
                        <Grid container>
                            <Grid item xs={12} lg={12} md={12}>
                                <Toolbar date={date} onClickNext={handleDateNext} onClickPrev={handleDatePrev} />
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sx={{ pl: 3 }}>
                                <Typography variant="dashboardCardContent">Choose the date to add Robert&lsquo;s meal plan</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                md={12}
                                sx={{
                                    '& .fc-daygrid-day': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                <SubCard>
                                    <FullCalendar
                                        weekends
                                        editable
                                        // droppable
                                        selectable
                                        events={events}
                                        ref={calendarRef}
                                        rerenderDelay={10}
                                        initialDate={date}
                                        now={date}
                                        eventDragMinDistance={1000}
                                        initialView={view}
                                        dayMaxEventRows={4}
                                        eventDisplay="block"
                                        headerToolbar={false}
                                        allDayMaintainDuration
                                        // displayEventTime={false}
                                        eventTimeFormat={eventTimeFormat}
                                        eventResizableFromStart
                                        eventClick={handleEventSelect}
                                        select={handleRangeSelect}
                                        eventDrop={handleEventUpdate}
                                        eventResize={handleEventUpdate}
                                        height={matchSm ? 'auto' : 'auto'}
                                        plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                                    />
                                </SubCard>
                            </Grid>
                        </Grid>
                    </CalendarStyled>
                </>
            )}
            {openEvent && <CustomMealModal data={data} handleClose={handleCloseEvent} />}
            {showCreateMealPlanContent && <CreateMealPlan range={selectedRange} handleReturn={returnToCalendarView} />}
        </>
    );
};

export default Calendar;
