import { useTheme, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import TodaysMealPlanTable from './TodaysMealPlanTable';
import TodaysMealPlanCard from './TodaysMealPlanCard';

const data = [
    { time: '9:00 AM', title: 'Breakfast', food: 'Yogurt, Berries, Granola', status: 'completed' },
    { time: '12:00 PM', title: 'Lunch', food: '4 oz Chicken, Salad', status: 'pending' },
    { time: '5:30 PM', title: 'Snacks', food: 'Peanut Pancakes', status: 'pending' },
    { time: '7:30 PM', title: 'Dinner', food: '1 Cup Oats', status: 'pending' }
];

const TodaysMealPlan = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <TodaysMealPlanTable />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={gridSpacing}>
                        {data.map((item, index) => (
                            <>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TodaysMealPlanCard data={item} />
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default TodaysMealPlan;
