// material ui
import { Grid } from '@mui/material';

// third-party
import ReCAPTCHA from 'react-google-recaptcha';

// project imports
import { gridSpacing } from 'store/constant';

// ==============================|| PLUGIN - GOOGLE RECAPTCHA ||============================== //

const CaptchaPage = () => {
    const handleOnChange = () => {};
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12} lg={6}>
                <ReCAPTCHA sitekey="6LcEzE4gAAAAAEN1ObXs-Rzm9QcoU8Kh12LI9OQx" onChange={handleOnChange} />
            </Grid>
        </Grid>
    );
};

export default CaptchaPage;
