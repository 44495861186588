import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Button, Grid, Stack, Typography, useMediaQuery, FormControlLabel, Checkbox } from '@mui/material';

// project imports
// import AuthWrapper2 from 'views/pages/authentication/AuthWrapper2';
// import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import Logo from 'ui-component/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { color, fontSize } from '@mui/system';
import pagenotfound from '../../assets/images/404/Vital-e-logo.png';
import Loaderimg from '../../assets/images/loader/loader.gif';
// import ProxyHeadingCard from './ProxyHeadingCard';
// ================================|| AUTH2 - LOGIN ||================================ //

const Loader = () => (
    <Grid item xs={12}>
        <Stack>
            <span
                style={{
                    width: '350px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    margin: '0 auto'
                }}
            >
                <img src={Loaderimg} alt="loader" style={{ width: '100%' }} />
            </span>
        </Stack>
    </Grid>
);

export default Loader;
