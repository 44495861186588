// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import {
    Box,
    ButtonBase,
    Checkbox,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// third-party
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { isChecked } from 'store/slices/mail';
import MainCard from 'ui-component/cards/MainCard';
// project imports
import Chip from 'ui-component/extended/Chip';
import MessageEmpty from './MessageEmpty';
import MessageListHeader from './MessageListHeader';

const avatarImage = require.context('assets/images/careteam', true);

// ------------------------------
// ==============================|| TABLE HEADER ||============================== //

function EnhancedTableHead({ selected }) {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="none" colSpan={2}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    selected: PropTypes.array
};

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 && (
            <Typography color="inherit" variant="h4" component="div">
                {numSelected} Mail Selected
            </Typography>
        )}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ==============================|| CUSTOMER LIST ||============================== //

const MessageList = ({
    data,
    search,
    handleSearch,
    handleDrawerOpen,
    openMailSidebar,
    handleUserDetails,
    handleStarredChange,
    handleImportantChange
}) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dispatch = useDispatch();

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
            dispatch(isChecked(true));
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            dispatch(isChecked(true));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            dispatch(isChecked(true));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            dispatch(isChecked(true));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const [denseTable, setDenseTable] = React.useState(false);
    const handleDenseTable = () => {
        setDenseTable(!denseTable);
    };

    const darkBG = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.100';
    const handleSelectAllClick = (event) => {
        dispatch(isChecked(false));
        if (event.target.checked) {
            const newSelectedId = data.map((n) => n.sender.name);
            setSelected(newSelectedId);
            dispatch(isChecked(true));
            return;
        }
        setSelected([]);
    };

    React.useEffect(() => {
        if (selected.length === 0) {
            dispatch(isChecked(false));
        }
    }, [selected]);

    return (
        <>
            <Grid container spacing={matchDownSM ? 3 : 1}>
                <Grid item xs={12}>
                    <MessageListHeader
                        search={search}
                        handleSearch={handleSearch}
                        length={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDenseTable={handleDenseTable}
                    />
                </Grid>
                <Grid item xs={12}>
                    {data.length ? (
                        <MainCard content={false} sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50' }}>
                            {/* table */}
                            <TableContainer>
                                <Table
                                    size={denseTable ? 'small' : undefined}
                                    aria-labelledby="tableTitle"
                                    sx={{ minWidth: 320, '& td': { whiteSpace: 'nowrap', px: 0.75, py: denseTable ? 0.5 : 1.25 } }}
                                >
                                    <TableHead sx={{ backgroundColor: theme.palette.tableColor.tableHeaderBg }}>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                                    checked={data.length > 0 && selected.length === data.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{
                                                        'aria-label': 'select all'
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>Name</TableCell>

                                            <TableCell>Subject</TableCell>
                                            <TableCell>{}</TableCell>
                                            <TableCell align="center">Date & Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {selected.length > 0 && <EnhancedTableHead selected={selected} />}
                                    <TableBody>
                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const isItemSelected = isSelected(row.sender.name);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    sx={{
                                                        bgcolor: !row.isRead ? darkBG : '',
                                                        '& td:last-of-type>div': {
                                                            position: 'absolute',
                                                            top: '50%',
                                                            right: 5,
                                                            transform: 'translateY(-50%)',
                                                            display: 'none',
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark[800]
                                                                    : theme.palette.tableColor.tableRowBg,
                                                            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                                            borderRadius: '12px',
                                                            py: 1,
                                                            px: 1.75,
                                                            '& button + button': {
                                                                ml: 0.625
                                                            }
                                                        },
                                                        '&:hover': {
                                                            '& td:last-of-type>div': {
                                                                display: 'block'
                                                            }
                                                        }
                                                    }}
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={isItemSelected || (data.length > 0 && selected.length === data.length)}
                                                            // checked={isItemSelected}
                                                            color="primary"
                                                            onChange={(event) => handleClick(event, row.sender.name)}
                                                            inputProps={{
                                                                'aria-labelledby': labelId
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        id={labelId}
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={(e) => handleUserDetails(e, row)}
                                                    >
                                                        <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                                            <Grid item xs zeroMinWidth>
                                                                <ButtonBase disableRipple>
                                                                    <Typography
                                                                        align="left"
                                                                        variant={row.isRead ? 'body1' : 'subtitle1'}
                                                                        component="div"
                                                                    >
                                                                        {row.sender.name}{' '}
                                                                        {row.sender.role === 'Specialist' && (
                                                                            <Chip
                                                                                label={row.sender.role}
                                                                                size="small"
                                                                                // sx={{
                                                                                //     color: theme.palette.primary.main,
                                                                                //     bgcolor: theme.palette.primary.paper
                                                                                // }}
                                                                                chipcolor="secondary"
                                                                            />
                                                                        )}
                                                                        {row.sender.role === 'Clinical Care Team' && (
                                                                            <Chip
                                                                                label={row.sender.role}
                                                                                size="small"
                                                                                sx={{
                                                                                    color: theme.palette.primary.main,
                                                                                    bgcolor: theme.palette.primary.paper
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Typography>
                                                                </ButtonBase>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell sx={{ cursor: 'pointer' }} onClick={(e) => handleUserDetails(e, row)}>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'flex',
                                                                width: {
                                                                    xs: 220,
                                                                    md: 350,
                                                                    lg: 600,
                                                                    xl: openMailSidebar ? 480 : 700
                                                                }
                                                            }}
                                                        >
                                                            <Typography
                                                                variant={row.isRead ? 'body1' : 'subtitle1'}
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    display: 'block'
                                                                }}
                                                            >
                                                                {row.subject} - {row.message}
                                                            </Typography>
                                                            &nbsp;&nbsp;
                                                            {/* <Chip
                                                                label={row.topic}
                                                                size="small"
                                                                sx={{
                                                                    color: theme.palette.primary.main,
                                                                    bgcolor: theme.palette.primary.paper
                                                                }}
                                                            /> */}
                                                            <Chip label={row.topic} disabled size="small" variant="outlined" />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.attach && (
                                                            <IconButton size="small">
                                                                <AttachmentTwoToneIcon fontSize="small" />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ position: 'relative' }}>
                                                        {format(new Date(row.time), 'd MMM yy HH:mm a')}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainCard>
                    ) : (
                        <MessageEmpty />
                    )}
                </Grid>
                <Grid item xs={12} sx={{ pt: '0 !important', display: { xs: 'block', sm: 'none' } }}>
                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </>
    );
};

MessageList.propTypes = {
    data: PropTypes.array,
    handleDrawerOpen: PropTypes.func,
    handleUserDetails: PropTypes.func,
    handleStarredChange: PropTypes.func,
    handleImportantChange: PropTypes.func,
    handleSearch: PropTypes.func,
    search: PropTypes.string,
    openMailSidebar: PropTypes.bool
};

export default MessageList;
