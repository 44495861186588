import SubCard from 'ui-component/cards/SubCard';
import { Grid, Typography, Card, CardContent, useTheme, Button, Badge, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import CustomMealModal from './CustomMealModal';

const TodaysMealPlanCard = ({ data }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const cardStyle = {
        height: '230px',
        background: data.status === 'completed' ? theme.palette.success.light : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };
    const CustomizedBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            border: `1px solid ${theme.palette.primary.main}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    const renderBadge = (status) => {
        let obj = <></>;
        if (status === 'completed') {
            obj = (
                <>
                    <SuccessBadge badgeContent="Completed" />
                </>
            );
        } else {
            obj = <></>;
        }
        return <>{obj}</>;
    };

    const renderButtons = (status) => {
        let obj = <></>;
        if (status === 'completed') {
            obj = (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={12} md={12} align="center" />
                        <Grid item xs={12} lg={12} md={12} align="center">
                            <Button variant="outlined" size="small" onClick={handleOpen}>
                                <Typography>View</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            );
        } else {
            obj = (
                <>
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} lg={12} md={12} align="center">
                            <Typography variant="body1" color={theme.palette.black.black}>
                                Did Robert eat this?
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6} align="center">
                            <Button variant="contained" size="small">
                                <Typography>Yes</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6} align="center">
                            <Button variant="outlined" size="small" onClick={handleOpen}>
                                <Typography>No</Typography>
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} lg={12} md={12} align="center" />
                        <Grid item xs={12} lg={12} md={12} align="center">
                            <Button variant="outlined" size="small" onClick={handleOpen}>
                                <Typography>View</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            );
        }
        return <>{obj}</>;
    };
    return (
        <>
            <SubCard style={{ border: '0.1px solid #D3D3D3' }} contentSX={{ px: '2px' }} sx={cardStyle}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <CustomizedBadge badgeContent={data.time} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Typography variant="h3" color={theme.palette.text.dark} gutterBottom>
                            {data.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} align="center">
                        <Typography variant="body1">{data.food}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }} align="center">
                        {renderBadge(data.status)}
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }} align="center">
                        {renderButtons(data.status)}
                    </Grid>
                </Grid>
            </SubCard>
            {open && <CustomMealModal data={data} handleClose={handleClose} />}
        </>
    );
};

export default TodaysMealPlanCard;
