import { useTheme, Grid, Typography, CardContent, IconButton, Card } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MealPlanContainer from '../../../../../../components/mealplan/MealPlanContainer';

const DietCard = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    return (
        <>
            <Card sx={cardStyle}>
                <CardContent sx={{ p: '10px !important' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={11} md={11} lg={11}>
                            <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                <Typography variant="h4">Diet</Typography>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} align="right">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                <ExpandCircleDownOutlinedIcon color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {open && (
                <>
                    <MealPlanContainer />
                </>
            )}
        </>
    );
};

export default DietCard;
