import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Vital-e web.png';
import pagenotfound from '../../assets/images/404/404.png';

const PageNotFound = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ p: 3 }}>
            <Grid item xs={12}>
                <Stack sx={{ mt: 2 }}>
                    <img src={logo} alt="logo" width="150px" />
                </Stack>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Stack direction={matchDownSM ? 'column-reverse' : 'row'}>
                        <Grid item xs={12} sm={12} md={12} sx={{ display: matchDownSM ? 'block' : 'flex', alignItems: 'center' }}>
                            <Stack spacing={2} sx={{ textAlign: 'left' }}>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: '100px',
                                        fontWeight: 'bold',
                                        color: '#052C4B',
                                        textShadow: '0px 6px 6px #d1d1d1',
                                        textAlign: matchDownSM ? 'center' : 'left'
                                    }}
                                >
                                    404!
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: '#052C4B',
                                        fontWeight: 'normal',
                                        fontSize: '30px',
                                        paddingBottom: '10px',
                                        textAlign: matchDownSM ? 'center' : 'left'
                                    }}
                                >
                                    Sorry, the page you are looking for might
                                    <br /> have been removed or does not exist.
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        background: '#2196F3',
                                        p: 2,
                                        width: matchDownSM ? '100%' : '250px',
                                        fontSize: matchDownSM ? '20px' : '16px'
                                    }}
                                    component={Link}
                                    to="/"
                                >
                                    BACK TO HOME PAGE
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack spacing={2} sx={{ textAlign: 'center', p: 2 }}>
                                <img src={pagenotfound} alt="Page Not Found" width="100%" />
                            </Stack>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PageNotFound;
