import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';

// third-party
import OtpInput from 'react-otp-input-rc-17';
import { FormattedMessage } from 'react-intl';

// ============================|| STATIC - CODE VERIFICATION ||============================ //

const PatientAuthCodeVerification = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [otp, setOtp] = useState();
    const borderColor = theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300];
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OtpInput
                    value={otp}
                    onChange={(otpNumber) => setOtp(otpNumber)}
                    numInputs={6}
                    containerStyle={{ justifyContent: 'space-between' }}
                    inputStyle={{
                        width: '100%',
                        margin: '8px',
                        padding: '10px',
                        border: `1px solid ${borderColor}`,
                        borderRadius: 4,
                        ':hover': {
                            borderColor: theme.palette.primary.main
                        }
                    }}
                    focusStyle={{
                        outline: 'none',
                        border: `2px solid ${theme.palette.primary.main}`
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Button
                    disableElevation
                    fullWidth
                    // size="small"
                    type="submit"
                    variant="contained"
                    onClick={() => navigate('../provideronboarding/providerSignUp')}
                >
                    <FormattedMessage id="Verify_Code" />
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography textAlign="center">Did n&apos;t receive the code?</Typography>
            </Grid>
        </Grid>
    );
};
export default PatientAuthCodeVerification;
