import * as React from 'react';
// material-ui
import { useTheme } from '@emotion/react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    styled,
    tableCellClasses
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
// third-party
import { FormattedMessage } from 'react-intl';
// project imports
import Chip from 'ui-component/extended/Chip';
import PropTypes from 'prop-types';
// table data
function createData(ipAddress, country, region, dateAndTime, badgeText, badgeType, isLogin) {
    return {
        ipAddress,
        country,
        region,
        dateAndTime,
        badgeText,
        badgeType,
        isLogin
    };
}
function Row({ row }) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.ipAddress}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.country}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.region}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.dateAndTime}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>
                        {row.isLogin ? (
                            <Chip chipcolor={row.badgeType} label={row.badgeText} />
                        ) : (
                            <Chip chipcolor={row.badgeType} label={row.badgeText} />
                        )}
                    </b>
                </StyledTableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const data = [
    createData('192.158. 1.38', 'United States', 'Texas ', '05/01/2022, 10:30 AM', 'Yes', 'success', true),
    createData('192.168. 1.1', 'United States', 'Texas ', '05/01/2022, 10:00 AM', 'Yes', 'success', true),
    createData('192.168. 1.125', 'United States', 'Texas ', '05/01/2022, 10:00 AM', 'Yes', 'success', true),
    createData('192.168. 1.225', 'India', 'Kerala', '05/01/2022, 10:00 AM', 'No', 'error', false)
];

export default function MyLoginAttempts() {
    const theme = useTheme();
    const rowGrey = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        backgroundColor: theme.palette.common.grey,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState([]);
    React.useEffect(() => {
        setRows(data);
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        // <MainCard>
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12} lg={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        <FormattedMessage id="IP_Address" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="Country" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="Region" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="date-time" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormattedMessage id="login" />
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <Row key={index} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
                {/* table pagination */}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        // </MainCard>
    );
}
