import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { gridSpacing } from 'store/constant';
import { Grid, useTheme, Typography, Button, Stack, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import TodaysMealPlan from './contents/today/TodaysMealPlan';
import WeeklyMealPlan from './contents/weekly/WeeklyMealPlan';
import MonthlyMealPlan from './contents/monthly/MonthlyMealPlan';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const MealPlanContainer = ({ viewState, back }) => {
    const theme = useTheme();
    const location = useLocation();
    // const from = location.state;
    // viewState ? (from = viewState) : (from = location.state); */
    /* if (location===null)
     const {from}===null;
     else */
    const navigate = useNavigate();
    console.log(viewState);
    // console.log(location);

    const [view, setView] = useState();
    useEffect(() => {
        if (viewState !== undefined) {
            setView(viewState);
        } else {
            setView('today');
        }
    }, []);
    return (
        <>
            <Grid container sx={{ pl: '0px !important' }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                        // mt: '20px !important',
                        p: '10px !important',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: theme.palette.common.grey
                    }}
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid alignItems="center" container spacing={gridSpacing}>
                                <Grid item xs={6} lg={6} md={6}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <IconButton onClick={() => back()}>
                                            <NavigateBeforeIcon />
                                        </IconButton>
                                        <Typography variant="h3">Diet Plan</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item sx={6} lg={6} md={6} align="right">
                                    <ToggleButtonGroup
                                        aria-label="text alignment"
                                        sx={{
                                            outlineColor: theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px'
                                        }}
                                        color="primary"
                                        size="small"
                                        value={view}
                                        exclusive
                                        // onChange={handleChange}
                                    >
                                        <ToggleButton
                                            onClick={() => {
                                                setView('today');
                                            }}
                                            value="today"
                                            aria-label="left aligned"
                                        >
                                            Today
                                        </ToggleButton>
                                        <ToggleButton
                                            onClick={() => {
                                                setView('week');
                                            }}
                                            value="week"
                                            aria-label="right aligned"
                                        >
                                            Week
                                        </ToggleButton>
                                        <ToggleButton
                                            onClick={() => {
                                                setView('month');
                                            }}
                                            value="month"
                                            aria-label="right aligned"
                                        >
                                            Month
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                {view === 'week' && (
                                    <>
                                        <Grid item xs={8} lg={8} md={8} />
                                        <Grid item xs={4} lg={4} md={4} algin="right">
                                            <Stack direction="row" justifyContent="flex-end">
                                                <Button variant="text" startIcon={<NavigateBeforeIcon />}>
                                                    Previous Week
                                                </Button>
                                                <Button variant="text" endIcon={<NavigateNextIcon />}>
                                                    Next Week
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sx={12} lg={12} md={12}>
                            {view === 'today' && (
                                <>
                                    <TodaysMealPlan />
                                </>
                            )}
                            {view === 'week' && (
                                <>
                                    <WeeklyMealPlan />
                                </>
                            )}
                            {view === 'month' && (
                                <>
                                    <MonthlyMealPlan />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MealPlanContainer;
