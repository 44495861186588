import { gridSpacing } from 'store/constant';
import { useTheme, Typography, Grid, IconButton, TextField, Stack, Button } from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ItemAttachments from './ItemAttachments';

const AddReferenceMaterialModalContent = () => {
    const theme = useTheme();
    const [showuploadFilesDiv, setShowUploadFilesDiv] = useState(false);
    const [showAddLinkDiv, setShowAddLinkDiv] = useState(false);
    const [showAddNotesDiv, setShowAddNotesDiv] = useState(false);
    const [addMoreLinks, setAddMoreLinks] = useState(false);
    const cardStyle = {
        background: theme.palette.cardColor.mainCardContentsBg,
        border: `1px solid ${theme.palette.common.grey}`,
        p: '16px !important',
        mb: '10px'
    };
    return (
        <>
            <Grid container>
                <Grid item container xs={12} lg={12} md={12} sx={cardStyle} alignItems="center">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography variant="h4">Upload Reference Materials</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} align="right">
                        <IconButton aria-label="expand row" size="small" onClick={() => setShowUploadFilesDiv(!showuploadFilesDiv)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                    </Grid>
                    {showuploadFilesDiv && (
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }} align="center">
                            <ItemAttachments />
                        </Grid>
                    )}
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={cardStyle} alignItems="center">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography variant="h4">Add Link</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} align="right">
                        <IconButton aria-label="expand row" size="small" onClick={() => setShowAddLinkDiv(!showAddLinkDiv)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                    </Grid>
                    {showAddLinkDiv && (
                        <>
                            <Grid item container xs={12} lg={12} md={12} sx={{ mt: 0.5 }} spacing={1} alignItems="center">
                                {addMoreLinks && (
                                    <>
                                        <Grid item xs={10} lg={10} md={10}>
                                            <TextField size="small" fullWidth label="Enter the link" />
                                        </Grid>
                                        <Grid item xs={1} lg={1} md={1} align="right">
                                            <IconButton onClick={() => setAddMoreLinks(!addMoreLinks)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={10} lg={10} md={10}>
                                    <TextField size="small" fullWidth label="Enter the link" />
                                </Grid>
                                {addMoreLinks && (
                                    <Grid item xs={1} lg={1} md={1} align="right">
                                        <IconButton onClick={() => setAddMoreLinks(!addMoreLinks)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item xs={1} lg={1} md={1} align="right">
                                    <IconButton onClick={() => setAddMoreLinks(!addMoreLinks)}>
                                        <AddCircleIcon color="primary" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sx={cardStyle} alignItems="center">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography variant="h4">Add Notes</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} align="right">
                        <IconButton aria-label="expand row" size="small" onClick={() => setShowAddNotesDiv(!showAddNotesDiv)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                    </Grid>
                    {showAddNotesDiv && (
                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 1 }}>
                            <TextField size="small" fullWidth multiline label="Notes" />
                        </Grid>
                    )}
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12}>
                    <Stack direction="row" spacing={1}>
                        <Button size="small" variant="contained">
                            Save
                        </Button>
                        <Button size="small" variant="outlined">
                            Cancel
                        </Button>
                    </Stack>
                </Grid> */}
            </Grid>
        </>
    );
};

export default AddReferenceMaterialModalContent;
