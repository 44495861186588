import { useTheme, Grid, Stack, IconButton, Typography, Button, Alert } from '@mui/material';
import { gridSpacing } from 'store/constant';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';
import CreateMealPlanTabs from './CreateMealPlanTabs';
import CreateMealPlanPanel from './CreateMealPlanPanel';
import { useState } from 'react';
import DietaryPreferencesModal from './DietaryPreferencesModal';

const CreateMealPlan = ({ range, handleReturn }) => {
    const theme = useTheme();
    const [showDietaryPreferenceModal, openDietaryPreferenceMOdal] = useState(false);
    const handleClose = () => {
        openDietaryPreferenceMOdal(false);
    };
    // console.log(range.start);
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Stack direction="row" alignItems="center">
                                <IconButton onClick={() => handleReturn()} size="small">
                                    <KeyboardArrowLeftTwoToneIcon size="large" sx={{ fontSize: '25px' }} />
                                </IconButton>
                                <Typography variant="h3">Create Meal Plan for 05 June, 2022</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Button
                                variant="text"
                                onClick={() => {
                                    openDietaryPreferenceMOdal(true);
                                }}
                            >
                                Add/View Dietary Preferences
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container>
                        <Grid item xs={4} lg={4} md={4}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Alert severity="info" icon={false}>
                                    Calorie Goal:&nbsp;<strong>2500 Cal</strong>
                                </Alert>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Alert severity="info" icon={false}>
                                    Remaining Calorie:&nbsp;<strong>1000 Cal</strong>
                                </Alert>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} lg={12} md={12}>
                            <CreateMealPlanTabs />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <CreateMealPlanPanel />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={9}>
                        <Grid item xs={1} lg={1} md={1}>
                            <Button variant="contained" onClick={() => handleReturn()}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={1} lg={1} md={1}>
                            <Button variant="outlined" onClick={() => handleReturn()}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {showDietaryPreferenceModal && <DietaryPreferencesModal closeModal={handleClose} />}
        </>
    );
};

export default CreateMealPlan;
