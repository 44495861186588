// material-ui
import { useTheme } from '@emotion/react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
    tableCellClasses,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

import PropTypes from 'prop-types';

// table data
function createData(task, calories, protein, carbs, fiber, sugar, fat) {
    return {
        task,
        calories,
        protein,
        carbs,
        fiber,
        sugar,
        fat
    };
}

function Row({ row }) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <>
            <TableRow>
                <StyledTableCell component="th" scope="row">
                    <b>{row.task}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.calories}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.protein}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.carbs}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.fiber}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.sugar}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.fat}</b>
                </StyledTableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [
    createData('Goal', '2500', '1000', '800', '400', '200', '400'),
    createData('Acheived', '1200', '600', '600', '250', '160', '250')
];

export default function TodaysMealPlanTable() {
    const theme = useTheme();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.grey,
            color: theme.palette.black.black,
            background: theme.palette.tableColor.tableHeaderBg
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h4">Goal vs Achieved</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <MainCard content={false}>
                    {/* table */}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell />
                                    <StyledTableCell>Calories</StyledTableCell>
                                    <StyledTableCell>Protein</StyledTableCell>
                                    <StyledTableCell>Carbs</StyledTableCell>
                                    <StyledTableCell>Fiber</StyledTableCell>
                                    <StyledTableCell>Sugar</StyledTableCell>
                                    <StyledTableCell>Fat</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <Row key={index} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
        </Grid>
    );
}
