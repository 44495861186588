import SubCard from 'ui-component/cards/SubCard';
import { Grid, Typography, Card, CardContent, useTheme, Button, Badge, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import CustomWeeklyMealModal from './CustomWeeklyMealModal';

const WeeklyMealPlanCard = ({ data }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const cardStyle = {
        // height: '250px',
        background: data.status === 'completed' ? theme.palette.success.light : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 6,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const SuccessBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: theme.palette.success.dark,
            color: theme.palette.primary.light,
            width: '80px',
            hieght: '100px',
            top: 3,
            border: `1px solid ${theme.palette.success.dark}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }));

    const renderFooter = (status) => {
        let obj = <></>;
        if (status === 'completed') {
            obj = (
                <>
                    <Grid item xs={6} md={6} lg={6} align="center">
                        <SuccessBadge badgeContent="Completed" />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} align="center">
                        <Button variant="outlined" size="small">
                            <Typography>View</Typography>
                        </Button>
                    </Grid>
                </>
            );
        } else {
            obj = (
                <>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Button variant="outlined" size="small" onClick={handleOpen}>
                            {/* onClick={handleOpen} */}
                            <Typography>View</Typography>
                        </Button>
                    </Grid>
                </>
            );
        }
        return <>{obj}</>;
    };

    return (
        <>
            <SubCard style={{ border: '0.1px solid #D3D3D3' }} contentSX={{ px: '2px' }} sx={cardStyle}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Typography variant="h4" color={theme.palette.text.dark} gutterBottom>
                            {data.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Typography variant="h3" color={theme.palette.text.dark} gutterBottom>
                            {data.day}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Card style={{ border: '1px solid', borderColor: '#e9e8e8' }} sx={{ mx: '10px' }}>
                            <PerfectScrollbar component="div" style={{ height: '100%', maxHeight: '200px', overflowX: 'hidden' }}>
                                <CardContent sx={{ px: 0, py: 2 }}>
                                    <Grid container>
                                        {data.content.map((item, key) => (
                                            <>
                                                <Grid item xs={12} md={12} lg={12} align="center">
                                                    <Typography variant="h6" color={theme.palette.grey[500]} gutterBottom>
                                                        {item.activity}-{item.time}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} align="center" sx={{ pb: 2 }}>
                                                    <Typography variant="dashboardCardContent" color={theme.palette.text.dark} gutterBottom>
                                                        {item.item}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>
                                </CardContent>
                            </PerfectScrollbar>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} align="center" sx={{ mt: 2 }}>
                        <Grid container>{renderFooter(data.status)}</Grid>
                    </Grid>
                </Grid>
            </SubCard>
            {open && <CustomWeeklyMealModal data={data} handleClose={handleClose} />}
        </>
    );
};

export default WeeklyMealPlanCard;
