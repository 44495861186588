import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
// assets
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import SearchIcon from '@mui/icons-material/Search';
// material-ui
import { Grid, IconButton, InputAdornment, Menu, MenuItem, Stack, TablePagination, TextField, useMediaQuery } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
// third-party
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// ==============================|| MAIL LIST HEADER ||============================== //

const MessageListHeader = ({
    search,
    handleSearch,
    length,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDrawerOpen,
    handleDenseTable
}) => {
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const mailCheckedState = useSelector((state) => state.mail.isChecked);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickSort = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSort = () => {
        setAnchorEl(null);
    };
    const intl = useIntl();
    const theme = useTheme();
    return (
        <Grid container alignItems="center" justifyContent="space-between">
            {/* <Grid item xs>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.5}>
                    <IconButton onClick={handleDrawerOpen} size="small">
                        <MenuRoundedIcon fontSize="small" />
                        <Box m={1} pt={1}>
                            <MuiTypography variant="h4" gutterBottom>
                                <FormattedMessage id="Inbox" />
                            </MuiTypography>
                        </Box>
                    </IconButton>
                    <IconButton onClick={handleClickSort} size="small">
                        <MoreHorizTwoToneIcon fontSize="small" />
                    </IconButton>
                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseSort}>
                        <MenuItem onClick={handleCloseSort}>
                            <FormattedMessage id="Name" />
                        </MenuItem>
                        <MenuItem onClick={handleCloseSort}>
                            <FormattedMessage id="Date" />
                        </MenuItem>
                        <MenuItem onClick={handleCloseSort}>
                            <FormattedMessage id="Rating" />
                        </MenuItem>
                        <MenuItem onClick={handleCloseSort}>
                            <FormattedMessage id="Unread" />
                        </MenuItem>
                    </Menu>
                    {mailCheckedState && <DeleteTwoToneIcon sx={{ color: theme.palette.error.main }} fontSize="small" />}

                    <TextField
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                        fullWidth={matchDownSM}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                        placeholder={intl.formatMessage({ id: 'Search' })}
                        value={search}
                        size="small"
                    />
                </Stack>
            </Grid> */}
            <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                        placeholder={intl.formatMessage({ id: 'Search' })}
                        value={search}
                        size="small"
                    />
                    {/* table pagination */}
                    <TablePagination
                        sx={{ '& .MuiToolbar-root': { pl: 1 } }}
                        rowsPerPageOptions={[]}
                        component="div"
                        count={length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

MessageListHeader.propTypes = {
    search: PropTypes.string,
    length: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    handleSearch: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangePage: PropTypes.func,
    handleDrawerOpen: PropTypes.func,
    handleDenseTable: PropTypes.func
};

export default MessageListHeader;
