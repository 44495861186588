import { useEffect, useState } from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import MuiTypography from '@mui/material/Typography';

// project imports

import { openDrawer } from 'store/slices/menu';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import { getMails, filterMails, setImportant, setStarred, setRead } from 'store/slices/mail';
import MessageDrawer from './MessageDrawer';
import MessageDetails from './MessageDetails';
import MessageList from './MessageList';
import ComposeDialog from './ComposeDialog';

// drawer content element
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: 'calc(100% - 320px)',
    flexGrow: 1,
    paddingLeft: open ? theme.spacing(3) : 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
    }),
    marginLeft: `-${drawerWidth}px`,
    [theme.breakpoints.down('xl')]: {
        paddingLeft: 0,
        marginLeft: 0
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0
    })
}));

// ==============================|| MAIL MAIN PAGE ||============================== //

const MailPage = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    const dispatch = useDispatch();

    const [emailDetails, setEmailDetailsValue] = useState(false);
    const [selectedMail, setSelectedMail] = useState(null);
    const handleUserChange = async (data) => {
        if (data) {
            await dispatch(setRead(data.id));
            await dispatch(getMails());
        }
        setSelectedMail(data);
        setEmailDetailsValue((prev) => !prev);
    };

    const [openMailSidebar, setOpenMailSidebar] = useState(true);
    const handleDrawerOpen = () => {
        setOpenMailSidebar((prevState) => !prevState);
    };

    useEffect(() => {
        if (matchDownSM) {
            setOpenMailSidebar(false);
        } else {
            setOpenMailSidebar(false);
        }
    }, [matchDownSM]);

    const [data, setData] = useState([]);
    const [unreadCounts, setUnreadCounts] = useState();
    const mailState = useSelector((state) => state.mail);

    useEffect(() => {
        setData(mailState.mails);
        setUnreadCounts(mailState.unreadCount);
    }, [mailState]);

    useEffect(() => {
        // hide left drawer when email app opens
        // dispatch(openDrawer(false));
        // getData();
        dispatch(getMails());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [filter, setFilter] = useState('all');
    const handleFilter = async (string) => {
        setEmailDetailsValue(false);
        setFilter(string);
        await dispatch(filterMails(string));
    };

    const handleImportantChange = async (event, dataImportant) => {
        if (dataImportant) {
            await dispatch(setImportant(dataImportant.id));
            handleFilter(filter);
        }
    };

    const handleStarredChange = async (event, dataStarred) => {
        if (dataStarred) {
            await dispatch(setStarred(dataStarred.id));
            handleFilter(filter);
        }
    };

    // search email using name
    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        const newString = event.target.value;
        setSearch(newString);

        if (newString) {
            const newRows = data.filter((row) => {
                let matches = true;

                const properties = ['name'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row.profile[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setData(newRows);
        } else {
            handleFilter(filter);
        }
    };

    return (
        <Grid item xs={12} lg={12} md={12}>
            <Grid xs={12} sx={{ display: 'flex', padding: '0px 24px 20px' }}>
                <MuiTypography sx={{ padding: 1 }} variant="h3" gutterBottom>
                    <FormattedMessage id="Messages" />
                </MuiTypography>
                <Stack sx={{ paddingLeft: '20px' }}>
                    <ComposeDialog />
                </Stack>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    '& .MuiListItemButton-root': {
                        '&.Mui-selected': {
                            color: theme.palette.primary.paper,
                            backgroundColor: theme.palette.primary.paper,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.paper
                            },
                            '& .MuiListItemIcon-root': {
                                color: theme.palette.primary.dark
                            }
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.primary.paper,
                            color: theme.palette.primary.paper,
                            '& .MuiListItemIcon-root': {
                                color: theme.palette.primary.dark
                            }
                        }
                    }
                }}
            >
                <MessageDrawer
                    openMailSidebar={openMailSidebar}
                    handleDrawerOpen={handleDrawerOpen}
                    filter={filter}
                    handleFilter={handleFilter}
                    unreadCounts={unreadCounts}
                />
                <Main theme={theme} open={openMailSidebar}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            {/* mail details & list */}
                            {emailDetails ? (
                                <MessageDetails
                                    data={selectedMail}
                                    handleUserDetails={(e, d) => handleUserChange(d)}
                                    handleImportantChange={handleImportantChange}
                                    handleStarredChange={handleStarredChange}
                                />
                            ) : (
                                <MessageList
                                    handleUserDetails={(e, d) => handleUserChange(d)}
                                    handleDrawerOpen={handleDrawerOpen}
                                    openMailSidebar={openMailSidebar}
                                    handleImportantChange={handleImportantChange}
                                    handleStarredChange={handleStarredChange}
                                    data={data}
                                    search={search}
                                    handleSearch={handleSearch}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Main>
            </Box>
        </Grid>
    );
};

export default MailPage;
