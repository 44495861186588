// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"primaryLight": "#e4e7ec",
	"primary200": "#909ab0",
	"primaryMain": "#203461",
	"primaryDark": "#1c2f59",
	"primary800": "#132145",
	"secondaryLight": "#fde8ef",
	"secondary200": "#f6a0bd",
	"secondaryMain": "#ec407a",
	"secondaryDark": "#ea3a72",
	"secondary800": "#e42a5d",
	"successLight": "#e3f8e8",
	"success200": "#8be09f",
	"successMain": "#17c13e",
	"successDark": "#14bb38",
	"errorLight": "#e48784",
	"errorMain": "#d9534f",
	"errorDark": "#d54c48",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fdf5ea",
	"warningMain": "#f0ad4e",
	"warningDark": "#ec9c3d",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"darkPaper": "#030614",
	"darkBackground": "#0a0f23",
	"darkLevel1": "#070e13",
	"darkLevel2": "#12172f",
	"darkTextTitle": "#e4e8f7",
	"darkTextPrimary": "#d5d9e9",
	"darkTextSecondary": "#d8ddf0",
	"darkPrimaryLight": "#ecedf1",
	"darkPrimaryMain": "#606d88",
	"darkPrimaryDark": "#586580",
	"darkPrimary200": "#b0b6c4",
	"darkPrimary800": "#44506b",
	"darkSecondaryLight": "#fde8ef",
	"darkSecondaryMain": "#ec407a",
	"darkSecondaryDark": "#ea3a72",
	"darkSecondary200": "#f6a0bd",
	"darkSecondary800": "#e42a5d"
};
export default ___CSS_LOADER_EXPORT___;
