import {
    Button,
    Grid,
    CardActions,
    IconButton,
    Typography,
    CardContent,
    Card,
    Modal,
    TextField,
    Autocomplete,
    FormGroup
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import MuiTooltip from '@mui/material/Tooltip';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { gridSpacing } from 'store/constant';
import MealPlanTab from './MealPlanTab';

const CustomWeeklyMealModal = ({ data, handleClose }) => {
    const rootRef = useRef(null);
    const [showAddDiv, setShowAddDiv] = useState(false);
    const handleDelete = () => {
        setShowAddDiv(false);
    };
    const handleAdd = () => {
        setShowAddDiv(true);
    };
    const theme = useTheme();
    const cardStyle = {
        p: 2,
        // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const autocompleteList1 = [
        { label: 'Yogurt (59 Cals)', id: 1 },
        { label: 'Oats (95 Cals)', id: 2 },
        { label: 'Berries (57 Cals)', id: 3 }
    ];
    const autocompleteList2 = [
        { label: '1', id: 1 },
        { label: '2', id: 2 },
        { label: '3', id: 3 }
    ];

    const autocompleteList3 = [
        { label: 'Oz', id: 1 },
        { label: 'Medium Serving', id: 2 },
        { label: 'Large Serving', id: 3 },
        { label: 'Cup', id: 4 }
    ];

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container>
            <Grid xs={12} lg={12}>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open
                    aria-labelledby="proxyconnection-modal-title"
                    aria-describedby="proxy-modal-description"
                    sx={{
                        display: 'flex',
                        p: 1,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    container={() => rootRef.current}
                >
                    <MainCard
                        sx={{
                            width: 1000,
                            zIndex: 1
                        }}
                        title={<>What did you have on 05-07-2022, Monday?</>}
                        content={false}
                        secondary={
                            <IconButton size="large" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    >
                        <Card sx={cardStyle}>
                            <CardContent sx={{ p: 1 }}>
                                <MealPlanTab />
                                <FormGroup>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} sx={{ pb: 1 }}>
                                                    <Typography variant="dashboardCardContent">
                                                        Choose the items to add to Robert&lsquo;s meal plan
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        options={autocompleteList1}
                                                        defaultValue={autocompleteList1[1]}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={<>Choose what Robert had on Monday</>} />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <MuiTooltip
                                                        title="12 gm Carbs | 6.52 gm Fat | 13.15 gm Protein (Per 100gm)"
                                                        aria-label="download"
                                                    >
                                                        <IconButton aria-label="info" size="small">
                                                            <InfoIcon color="primary" fontSize="small" />
                                                        </IconButton>
                                                    </MuiTooltip>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        options={autocompleteList2}
                                                        defaultValue={autocompleteList2[0]}
                                                        renderInput={(params) => <TextField {...params} label="Quantity" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Autocomplete
                                                        disableClearable
                                                        options={autocompleteList3}
                                                        defaultValue={autocompleteList3[3]}
                                                        renderInput={(params) => <TextField {...params} label="Servings" />}
                                                    />
                                                </Grid>
                                                {showAddDiv && (
                                                    <Grid item xs={1}>
                                                        <IconButton aria-label="expand row">
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                                {!showAddDiv && (
                                                    <Grid item xs={1}>
                                                        <IconButton sx={{ color: theme.palette.primary.main }} onClick={handleAdd}>
                                                            <AddCircleIcon sx={{ fontSize: '35px' }} />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {showAddDiv && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={4}>
                                                        <Autocomplete
                                                            options={autocompleteList1}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label={<>Choose what Robert had on Monday</>} />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <MuiTooltip title="Choose" aria-label="download">
                                                            <IconButton aria-label="info" size="small">
                                                                <InfoIcon color="primary" fontSize="small" />
                                                            </IconButton>
                                                        </MuiTooltip>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Autocomplete
                                                            disableClearable
                                                            options={autocompleteList2}
                                                            renderInput={(params) => <TextField {...params} label="Quantity" />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Autocomplete
                                                            disableClearable
                                                            options={autocompleteList3}
                                                            renderInput={(params) => <TextField {...params} label="Servings" />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} onClick={handleDelete}>
                                                        <IconButton aria-label="expand row">
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton sx={{ color: theme.palette.primary.main }}>
                                                            <AddCircleIcon sx={{ fontSize: '35px' }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </FormGroup>
                            </CardContent>
                        </Card>
                        <CardActions>
                            <Grid container spacing={2} justifyContent="left">
                                <Grid item>
                                    <Button variant="contained" onClick={handleClose}>
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </MainCard>
                </Modal>
            </Grid>
        </Grid>
    );
};
export default CustomWeeklyMealModal;
