import { Grid, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import { gridSpacing } from 'store/constant';
import WeightChart from '../../../../rpm/monitoredvalues/charts/WeightChart';
import MonitoredValuesTable from '../../../../summary/components/monitoredvalues/MonitoredValuesTable';

const ViewProgress = () => {
    const theme = useTheme();
    const [view, setView] = useState('list');
    // [
    //     'Sugar',
    //     <>
    //         <Grid container spacing={1}>
    //             <Grid
    //                 item
    //                 sx={{
    //                     color: `${theme.palette.error.main} !important`
    //                 }}
    //             >
    //                 <b>141</b>
    //             </Grid>
    //             <Grid item>(Past Dinner)</Grid>
    //         </Grid>
    //     </>,
    //     '06/05/2022',
    //     'success'
    // ]
    const weight = {
        title: ['Date', 'Weight (lbs)', 'Source'],
        contents: [
            ['06/01/2022', '240', 'Robert Hailey'],
            ['06/02/2022', '210', 'Robert Hailey'],
            ['06/03/2022', '189', 'Robert Hailey'],
            ['06/04/2022', '188', 'Robert Hailey'],
            ['06/05/2022', '192', 'Robert Hailey'],
            ['06/06/2022', '190', 'Robert Hailey'],
            ['06/07/2022', '184', 'Robert Hailey']
        ]
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid container item xs={12} lg={12} md={12} spacing={gridSpacing} alignItems="center">
                <Grid item xs={5} lg={5} md={5}>
                    <Typography variant="h4">View Weight Progress</Typography>
                </Grid>
                <Grid item sx={7} lg={7} md={7} align="right">
                    <ToggleButtonGroup
                        aria-label="text alignment"
                        sx={{
                            outlineColor: theme.palette.primary.main,
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            margin: '2px'
                        }}
                        color="primary"
                        size="small"
                        value={view}
                        exclusive
                        // onChange={handleChange}
                    >
                        <ToggleButton
                            onClick={() => {
                                setView('list');
                            }}
                            value="list"
                            aria-label="left aligned"
                        >
                            List View
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => {
                                setView('graph');
                            }}
                            value="graph"
                            aria-label="right aligned"
                        >
                            Graph View
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                {view === 'list' && (
                    <Grid item xs={12} lg={12} md={12}>
                        <Grid item container xs={12} lg={12} md={12} spacing={2}>
                            <Grid item xs={12} lg={12} md={12}>
                                <MonitoredValuesTable data={weight} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {view === 'graph' && (
                    <Grid item xs={12} lg={12} md={12}>
                        <Grid item container xs={12} lg={12} md={12} spacing={2}>
                            <Grid item xs={12} lg={12} md={12}>
                                <WeightChart />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ViewProgress;
