// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"primaryLight": "#e2e5e8",
	"primary200": "#8591a1",
	"primaryMain": "#0a2342",
	"primaryDark": "#091f3c",
	"primary800": "#05152b",
	"secondaryLight": "#e6f4f1",
	"secondary200": "#96d2c6",
	"secondaryMain": "#2ca58d",
	"secondaryDark": "#279d85",
	"secondary800": "#1b8a70",
	"successLight": "#b9f6ca",
	"success200": "#69f0ae",
	"successMain": "#00e676",
	"successDark": "#00c853",
	"errorLight": "#ef9a9a",
	"errorMain": "#f44336",
	"errorDark": "#c62828",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fff8e1",
	"warningMain": "#ffe57f",
	"warningDark": "#ffc107",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"darkPaper": "#030c1d",
	"darkBackground": "#051327",
	"darkLevel1": "#071a33",
	"darkLevel2": "#091f3c",
	"darkTextTitle": "#d7dcec",
	"darkTextPrimary": "#bdc8f0",
	"darkTextSecondary": "#8492c4",
	"darkPrimaryLight": "#e2e5e8",
	"darkPrimaryMain": "#54657b",
	"darkPrimaryDark": "#2f445e",
	"darkPrimary200": "#8591a1",
	"darkPrimary800": "#05152b",
	"darkSecondaryLight": "#e6f4f1",
	"darkSecondaryMain": "#2ca58d",
	"darkSecondaryDark": "#279d85",
	"darkSecondary200": "#96d2c6",
	"darkSecondary800": "#1b8a70"
};
export default ___CSS_LOADER_EXPORT___;
