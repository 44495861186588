// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"primaryLight": "#e3ebeb",
	"primary200": "#8bacad",
	"primaryMain": "#16595a",
	"primaryDark": "#135152",
	"primary800": "#0c3e3f",
	"secondaryLight": "#f8f0e5",
	"secondary200": "#e3bf91",
	"secondaryMain": "#c77e23",
	"secondaryDark": "#c1761f",
	"secondary800": "#b36115",
	"successLight": "#b9f6ca",
	"success200": "#69f0ae",
	"successMain": "#00e676",
	"successDark": "#00c853",
	"errorLight": "#ef9a9a",
	"errorMain": "#f44336",
	"errorDark": "#c62828",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fff8e1",
	"warningMain": "#ffe57f",
	"warningDark": "#ffc107",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"darkPaper": "#010f17",
	"darkBackground": "#010606",
	"darkLevel1": "#02131d",
	"darkLevel2": "#010f17",
	"darkTextTitle": "#ffffff",
	"darkTextPrimary": "#ffffff",
	"darkTextSecondary": "#8492c4",
	"darkPrimaryLight": "#eceff1",
	"darkPrimaryMain": "#1f7778",
	"darkPrimaryDark": "#1b6f70",
	"darkPrimary200": "#8fbbbc",
	"darkPrimary800": "#125a5b",
	"darkSecondaryLight": "#f8f0e5",
	"darkSecondaryMain": "#c77e23",
	"darkSecondaryDark": "#c1761f",
	"darkSecondary200": "#e3bf91",
	"darkSecondary800": "#b36115"
};
export default ___CSS_LOADER_EXPORT___;
