import { Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AppointmentsTab from '../appointmentstab';
import { useLocation, useNavigate } from 'react-router-dom';
import HealthRecordsBreadCrumbs from 'views/healthrecords/HealthRecordsBreadCrumbs';
import { gridSpacing } from 'store/constant';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';

export default function ExpansionCard({ option }) {
    const theme = useTheme();
    // const { value } = route.params;
    const location = useLocation();
    const navigate = useNavigate();
    const user = location.state;
    let value = user;
    if (option !== undefined) {
        value = option;
    }

    return (
        <>
            {/* <Grid container spacing={gridSpacing}>
                <Grid container direction="column" sx={{ p: 2 }}>
                    <Grid item xs={12} sx={{ ml: 2 }}>
                        <Typography variant="h3">Appointment Details on 05/06/2022 07:30 AM [PST]</Typography>
                    </Grid>
                </Grid>
            </Grid> */}
            {/* <MainCard
                content={false}
                sx={{
                    p: 2,
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? 'transparent' : '#e9e8e8',

                    '&:hover': {
                        border: `1px solid${theme.palette.primary.main}`
                    }
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Chip label="Office Visit" size="small" color="info" />
                        <Typography align="left" variant="h4" component="div" pt={1}>
                            05/06/2022 07:30 AM [PST] &nbsp;
                            <Chip
                                label="Completed"
                                size="small"
                                sx={{
                                    color: theme.palette.success.main,
                                    bgcolor: theme.palette.success.light
                                }}
                            />
                        </Typography>
                        <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                            <>
                                <b>Dr. Roger Miles - Sutter Health,</b> San Antonio, Texas
                            </>
                        </Typography>
                        <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                            <>
                                <b>Orthopedics</b>
                            </>
                        </Typography>
                        <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                            Illness
                        </Typography>
                    </Grid>
                </Grid>
            </MainCard> */}

            <Grid item xs={12}>
                <AppointmentsTab value1={value} />
            </Grid>
        </>
    );
}
