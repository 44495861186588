import CaptchaPage from 'views/patientonboarding/PatientCaptcha';
import { Button, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';

const ForgotUsernameCard = () => {
    const navigate = useNavigate();
    return (
        <Grid Container direction="column" justifyContent="center" spacing={gridSpacing}>
            <Grid item xs={12} pb={3}>
                <TextField size="small" label="Email" fullWidth id="outlined-default-email" placeholder="Email" />
            </Grid>
            <Grid item xs={12} pb={3}>
                <CaptchaPage />
            </Grid>

            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={() => navigate('../providerforgotusername/ForgotMailSent')}
                >
                    Send Mail
                </Button>
            </Grid>
        </Grid>
    );
};

export default ForgotUsernameCard;
