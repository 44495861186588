import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import PatientVerification from 'views/patientonboarding/PatientVerification';
import PatientVerificationCode from 'views/patientonboarding/PatientVerificationCode';
import ProviderVerificationCode from 'views/onboarding/ProviderVerificationCode';
import PatientSignUp from 'views/patientonboarding/PatientSignUp';
import SecurityQuestions from 'views/patientonboarding/SecurityQuestions';
import UserAgreement from 'views/patientonboarding/UserAgreement';
import PrivacyPolicy from 'views/patientonboarding/PrivacyPolicy';
import ProviderUserAgreement from 'views/onboarding/ProviderUserAgreement';
import ProviderPrivacyPolicy from 'views/onboarding/ProviderPrivacyPolicy';
import ProviderSignUp from 'views/onboarding/ProviderSignUp';
import ProviderSecurityQuestions from 'views/onboarding/ProviderSecurityQuestions';
import PatientForgotUsername from 'views/patientforgotusername';
import ForgotMailSent from 'views/patientforgotusername/ForgotMailSent';
import PatientPwdVerification from 'views/patientforgotpassword/PatientVerification';
import PatientPwdVerificationCode from 'views/patientforgotpassword/PatientVerificationCode';
import ForgotPassword from 'views/patientforgotpassword';
import ForgotPasswordSecurityQuestion from 'views/patientforgotpassword/ForgotPasswordSecurityQuestion';
import ResetPassword from 'views/patientforgotpassword/ResetPassword';
import PasswordChanged from 'views/patientforgotpassword/PasswordChanged';
import PageNotFound from 'views/PageNotfound/Pagenotfound';
import Forbidden from 'views/PageNotfound/Forbidden';
import SomethingWrong from 'views/PageNotfound/SomethingWrong';
import Loader from 'views/PageNotfound/Loader';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const PatientLogin = Loadable(lazy(() => import('views/patientonboarding')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const PatientDashboard = Loadable(lazy(() => import('views/patientonboarding/patientinitialdashboard')));
const ProviderOnBoard = Loadable(lazy(() => import('views/onboarding')));

// hub page
const HubPage = Loadable(lazy(() => import('views/hub')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/hub',
            element: <HubPage />
        },

        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },

        {
            path: '/onboarding',
            element: <ProviderOnBoard />
        },
        {
            path: '/onboarding/providerverificationCode',
            element: <ProviderVerificationCode />
        },

        {
            path: '/onboarding/providersignup',
            element: <ProviderSignUp />
        },
        {
            path: '/onboarding/providersecurityquestions',
            element: <ProviderSecurityQuestions />
        },
        {
            path: '/onboarding/providerprivacypolicy',
            element: <ProviderPrivacyPolicy />
        },
        {
            path: '/onboarding/provideruseragreement',
            element: <ProviderUserAgreement />
        },
        {
            path: '/providerforgotusername',
            element: <PatientForgotUsername />
        },
        {
            path: '/providerforgotusername/ForgotMailSent',
            element: <ForgotMailSent />
        },
        {
            path: '/providerforgotpassword',
            element: <ForgotPassword />
        },
        {
            path: '/providerforgotpassword/providerVerification',
            element: <PatientPwdVerification />
        },
        {
            path: '/providerforgotpassword/providerVerificationCode',
            element: <PatientPwdVerificationCode />
        },
        {
            path: '/providerforgotpassword/SecurityQuestion',
            element: <ForgotPasswordSecurityQuestion />
        },
        {
            path: '/providerforgotpassword/ResetPassword',
            element: <ResetPassword />
        },
        {
            path: '/providerforgotpassword/PasswordChanged',
            element: <PasswordChanged />
        },
        {
            path: '/Pagenotfound',
            element: <PageNotFound />
        },
        {
            path: '/Forbidden',
            element: <Forbidden />
        },
        {
            path: '/Wrongpage',
            element: <SomethingWrong />
        },
        {
            path: '/Loader',
            element: <Loader />
        }
    ]
};

export default LoginRoutes;
