import { useTheme, RadioGroup, FormControlLabel, Radio, Checkbox, Grid, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';

const CreateMealPlanPanel = () => {
    const theme = useTheme();
    const [repeatValue, setRepeatValue] = useState('1');
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3} md={3} lg={3}>
                        <FormControlLabel
                            control={<Checkbox defaultChecked name="checkedMobile" color="primary" />}
                            label="Repeat Meal Plan for"
                        />
                    </Grid>
                    <Grid item xs={1.5} md={1.5} lg={1.5}>
                        <TextField id="outlined-select-experience1" select fullWidth size="small">
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7}>
                        <RadioGroup
                            row
                            aria-label="repeatFor"
                            value={repeatValue}
                            onChange={(e) => setRepeatValue(e.target.value)}
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Days" />
                            <FormControlLabel value="2" control={<Radio />} label="Week" />
                            <FormControlLabel value="3" control={<Radio />} label="Month" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
                <Grid container spacing={1}>
                    <Grid item xs={3} md={3} lg={3}>
                        <FormControlLabel
                            control={<Checkbox defaultChecked name="checkedMobile" color="primary" />}
                            label="Remind me before"
                        />
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        <TextField id="outlined-select-experience1" defaultValue="1" select fullWidth size="small">
                            <MenuItem value="1">1 hour</MenuItem>
                            <MenuItem value="2">2 hours</MenuItem>
                            <MenuItem value="3">3 hours</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CreateMealPlanPanel;
