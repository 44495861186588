import {
    Button,
    Grid,
    CardActions,
    FormControl,
    IconButton,
    Select,
    MenuItem,
    Typography,
    CardContent,
    Card,
    Modal,
    TextField,
    Autocomplete,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormGroup,
    Stack,
    Badge,
    styled
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import MuiTooltip from '@mui/material/Tooltip';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { IconBrandTelegram } from '@tabler/icons';
import { Input, PhotoCamera } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { gridSpacing } from 'store/constant';

const ChooseSnacks = ({ data, handleClose }) => {
    const theme = useTheme();
    const cardStyle = {
        p: 2,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const cardHeaderStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.dashboardCardHeaderBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const autocompleteList1 = [
        { label: 'Yogurt (59 Cals)', id: 1 },
        { label: 'Oats (95 Cals)', id: 2 },
        { label: 'Berries (57 Cals)', id: 3 },
        { label: 'Add Recipe', id: 4 }
    ];
    const autocompleteList2 = [
        { label: '1', id: 1 },
        { label: '2', id: 2 },
        { label: '3', id: 3 }
    ];

    const autocompleteList3 = [
        { label: 'Oz', id: 1 },
        { label: 'Medium Serving', id: 2 },
        { label: 'Large Serving', id: 3 },
        { label: 'Cup', id: 4 }
    ];

    return (
        <Grid container>
            <Grid xs={12} lg={12}>
                <Card sx={cardStyle}>
                    <CardContent sx={{ p: 1 }}>
                        <FormGroup>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sx={{ pb: 3 }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography variant="h4">Choose Snacks</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        id="outlined-select-experience1"
                                                        select
                                                        fullWidth
                                                        label="Choose Time"
                                                        defaultValue="1"
                                                        size="small"
                                                    >
                                                        <MenuItem value="1">7:00 AM</MenuItem>
                                                        <MenuItem value="2">8:00 AM</MenuItem>
                                                        <MenuItem value="3">9:00 AM</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={autocompleteList1}
                                                defaultValue={autocompleteList1[1]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={<>Choose what Robert wants for snacks</>} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <MuiTooltip
                                                title="12 gm Carbs | 6.52 gm Fat | 13.15 gm Protein (Per 100gm)"
                                                aria-label="download"
                                            >
                                                <IconButton aria-label="info" size="small">
                                                    <InfoIcon color="primary" fontSize="small" />
                                                </IconButton>
                                            </MuiTooltip>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Autocomplete
                                                disableClearable
                                                options={autocompleteList2}
                                                defaultValue={autocompleteList2[0]}
                                                renderInput={(params) => <TextField {...params} label="Quantity" />}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                disableClearable
                                                options={autocompleteList3}
                                                defaultValue={autocompleteList3[3]}
                                                renderInput={(params) => <TextField {...params} label="Servings" />}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="expand row">
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={autocompleteList1}
                                                defaultValue={autocompleteList1[2]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={<>Choose what Robert wants for snacks</>} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <MuiTooltip title="Choose" aria-label="download">
                                                <IconButton aria-label="info" size="small">
                                                    <InfoIcon color="primary" fontSize="small" />
                                                </IconButton>
                                            </MuiTooltip>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Autocomplete
                                                disableClearable
                                                options={autocompleteList2}
                                                defaultValue={autocompleteList2[0]}
                                                renderInput={(params) => <TextField {...params} label="Quantity" />}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                disableClearable
                                                options={autocompleteList3}
                                                defaultValue={autocompleteList3[1]}
                                                renderInput={(params) => <TextField {...params} label="Servings" />}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="expand row">
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={autocompleteList1}
                                                defaultValue={autocompleteList1[0]}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={<>Choose what Robert wants for snacks</>} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <MuiTooltip title="Choose" aria-label="download">
                                                <IconButton aria-label="info" size="small">
                                                    <InfoIcon color="primary" fontSize="small" />
                                                </IconButton>
                                            </MuiTooltip>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Autocomplete
                                                disableClearable
                                                options={autocompleteList2}
                                                defaultValue={autocompleteList2[1]}
                                                renderInput={(params) => <TextField {...params} label="Quantity" />}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                disableClearable
                                                options={autocompleteList3}
                                                defaultValue={autocompleteList3[2]}
                                                renderInput={(params) => <TextField {...params} label="Servings" />}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="expand row">
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton sx={{ color: theme.palette.primary.main }}>
                                                <AddCircleIcon sx={{ fontSize: '35px' }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
export default ChooseSnacks;
