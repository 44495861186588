import { Grid, Typography, ToggleButtonGroup, ToggleButton, Link, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { gridSpacing } from 'store/constant';
import MonitoredValuesTable from '../../../../summary/components/monitoredvalues/MonitoredValuesTable';
import DeleteIcon from '@mui/icons-material/Delete';

const weight = {
    contents: [
        [
            <>
                <Link underline="none" sx={{ cursor: 'pointer' }}>
                    DietNutrtitionGuidelines.pdf&nbsp;&nbsp;
                </Link>
            </>,
            <>
                <IconButton>
                    <DeleteIcon color="error" />
                </IconButton>
            </>
        ],
        [
            <>
                <Link underline="none" sx={{ cursor: 'pointer' }}>
                    https://www.youtube.com/watch?v=MOyTc05g4oU
                </Link>
            </>,
            <>
                <IconButton>
                    <DeleteIcon color="error" />
                </IconButton>
            </>
        ]
    ]
};

const weightReadOnly = {
    contents: [
        [
            <>
                <Link underline="none" sx={{ cursor: 'pointer' }}>
                    DietNutrtitionGuidelines.pdf&nbsp;&nbsp;
                </Link>
            </>
        ],
        [
            <>
                <Link underline="none" sx={{ cursor: 'pointer' }}>
                    https://www.youtube.com/watch?v=MOyTc05g4oU
                </Link>
            </>
        ]
    ]
};

const ReferenceMaterial = ({ mode }) => {
    const theme = useTheme();
    let data = weight;
    if (mode !== undefined && mode === 2) {
        data = weightReadOnly;
    }
    return (
        <Grid container spacing={gridSpacing}>
            <Grid container item xs={12} lg={12} md={12} spacing={gridSpacing} alignItems="center">
                {/* <Grid item xs={5} lg={5} md={5}>
                    <Typography variant="h4">Reference Materials</Typography>
                </Grid> */}
                <Grid item xs={12} lg={12} md={12}>
                    <Grid item container xs={12} lg={12} md={12} spacing={2}>
                        <Grid item xs={12} lg={12} md={12}>
                            <MonitoredValuesTable data={data} />
                            {/* <Link underline="none" sx={{ cursor: 'pointer' }}>
                                DietNutrtitionGuidelines.pdf&nbsp;&nbsp;
                            </Link>
                            <br />
                            <Link underline="none" sx={{ cursor: 'pointer' }}>
                                https://www.youtube.com/watch?v=MOyTc05g4oU
                            </Link> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReferenceMaterial;
