import { useTheme, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import WeeklyMealPlanCard from './WeeklyMealPlanCard';

const data = [
    {
        day: 'Sunday',
        date: '05-06-2022',
        status: 'completed',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    },
    {
        day: 'Monday',
        date: '05-07-2022',
        status: 'pending',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    },
    {
        day: 'Tuesday',
        date: '05-08-2022',
        status: 'pending',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    },
    {
        day: 'Wednesday',
        date: '05-09-2022',
        status: 'pending',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    },
    {
        day: 'Thursday',
        date: '05-10-2022',
        status: 'pending',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    },
    {
        day: 'Friday',
        date: '05-11-2022',
        status: 'pending',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    },
    {
        day: 'Saturday',
        date: '05-12-2022',
        status: 'pending',
        content: [
            { time: '9:00 AM', activity: 'Breakfast', item: 'Yogurt, Berries, Granola' },
            { time: '12:00 PM', activity: 'Lunch', item: '4 oz Chicken, Salad' },
            { time: '5:30 PM', activity: 'Snacks', item: 'Peanut Pancakes' },
            { time: '7:30 PM', activity: 'Dinner', item: '1 Cup Oats' },
            { time: '10:30 PM', activity: 'Bed Time', item: 'Water' }
        ]
    }
];

const WeeklyMealPlan = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={gridSpacing}>
                        {data.map((item, index) => (
                            <Grid key={index} item xs={3} lg={3} md={3}>
                                <WeeklyMealPlanCard data={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default WeeklyMealPlan;
