import { useTheme, Grid, Typography, Switch, FormControlLabel, Button } from '@mui/material';
import GoalsTable from './GoalsTable';
import { gridSpacing } from 'store/constant';
import GoalsCard from './GoalsCard';

const Goals = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={2}>
                {/* <Grid item container xs={12} lg={12} md={12} alignItems="center">
                    <Grid item xs={1} lg={1} md={1}>
                        <Typography variant="h3">Goals</Typography>
                    </Grid> */}
                {/* <Grid item xs={2} lg={2} md={2} align="center">
                        <Button size="small" variant="contained">
                            Add Goals
                        </Button>
                    </Grid> */}
                {/* <Grid item xs={10} lg={10} md={10} align="right">
                        <FormControlLabel
                            value="end"
                            control={<Switch color="primary" />}
                            label="Show Inactive Goals"
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid> */}

                <Grid item xs={12} lg={12} md={12}>
                    {/* <GoalsTable /> */}
                    <GoalsCard />
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12}>
                    <NutritionGoalsCard />
                </Grid> */}
            </Grid>
        </>
    );
};

export default Goals;
