// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDashboard,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const ProxyMenuItems = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/proxydashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'messages',
            title: <FormattedMessage id="Messages" />,
            type: 'item',
            url: '/proxymessages',
            icon: icons.IconMail,
            breadcrumbs: false
        },
        {
            id: 'account-settings',
            title: <FormattedMessage id="Account_Settings" />,
            type: 'item',
            url: '/proxyaccountsettings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'how-it-works',
            title: <FormattedMessage id="how_it_works" />,
            type: 'item',
            url: '/howitworks',
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};

export default ProxyMenuItems;
