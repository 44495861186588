import { Box, Grid, Tab, Tabs } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';

import MyProfile from './MyProfile';
import ChangeCredential from './ChangeCredential';
// third-party
import { FormattedMessage } from 'react-intl';
import MyLoginAttempts from './MyLoginAttempts';

// tabs panel
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: <FormattedMessage id="My_Profile" />
    }
    // {
    //     label: <FormattedMessage id="Change_Credentials" />
    // },
    // {
    //     label: <FormattedMessage id="My_Login_Attempts" />
    // }
];

const AccountSettingsTabs = () => {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    sx={{
                        mb: 3,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 0,
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.primary.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        }
                    }}
                >
                    {tabsOption.map((tab, index) => (
                        <Tab key={index} component={Link} to="#" label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <MyProfile />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                    <ChangeCredential />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MyLoginAttempts />
                </TabPanel> */}
            </Grid>
        </Grid>
    );
};

export default AccountSettingsTabs;
