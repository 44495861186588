import {
    Button,
    Grid,
    CardActions,
    IconButton,
    Typography,
    CardContent,
    Card,
    TextField,
    Autocomplete,
    Dialog,
    DialogTitle,
    DialogContent
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const DietaryPreferencesModal = ({ closeModal }) => {
    const preferences = [
        { label: 'Vegetarian', id: 1 },
        { label: 'Vegetarian + Egg', id: 2 },
        { label: 'Non - Vegetarian', id: 3 }
    ];
    const restrictions = [
        { label: 'Lactose Intolerance', id: 1 },
        { label: 'Gluten Intolerance', id: 2 },
        { label: 'Sugar Free', id: 3 }
    ];
    const allergies = [
        { label: 'Fish', id: 1 },
        { label: 'Corn Flour', id: 2 },
        { label: 'Beef', id: 3 }
    ];
    const baseline = [
        { label: 'Zero Carbs', id: 1 },
        { label: 'Moderate Protein', id: 2 },
        { label: 'Protein', id: 3 },
        { label: 'Low Carbs', id: 4 },
        { label: 'Lean Protein', id: 5 },
        { label: 'Fruit', id: 6 }
    ];
    const theme = useTheme();
    const cardStyle = {
        p: 2,
        // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );

    return (
        <Grid container>
            <Grid xs={12} lg={12}>
                <Dialog
                    onClose={closeModal}
                    aria-labelledby="customized-dialog-title"
                    open
                    sx={{
                        '& .MuiDialog-paper': {
                            maxWidth: 'none',
                            width: 700,
                            height: 'calc(100% - 64px)'
                        },
                        '& .MuDialogContent-root': {
                            padding: theme.spacing(2)
                        },
                        '& .MuDialogActions-root': {
                            padding: theme.spacing(1)
                        },
                        '& .MuiAutocomplete-tag': {
                            background: theme.palette.primary.paper
                        }
                    }}
                >
                    <BootstrapDialogTitle onClose={closeModal} id="customized-dialog-title">
                        Add Your Dietary Preferences
                    </BootstrapDialogTitle>
                    <DialogContent sx={{ pt: '10px', pb: '10px' }}>
                        <Card sx={cardStyle}>
                            <CardContent sx={{ p: 1 }}>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container direction="column" spacing={3}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h4">Choose Your Dietary Preferences</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    options={preferences}
                                                    getOptionLabel={(option) => option.label}
                                                    defaultValue={[preferences[0], preferences[2]]}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container direction="column" spacing={3}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h4">Choose Your Dietary Restrictions</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    options={restrictions}
                                                    getOptionLabel={(option) => option.label}
                                                    defaultValue={[restrictions[0], restrictions[2]]}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container direction="column" spacing={3}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h4">Choose Your Food Allergies</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    options={allergies}
                                                    getOptionLabel={(option) => option.label}
                                                    defaultValue={[allergies[0], allergies[2]]}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container direction="column" spacing={3}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h4">Choose Your Base Line Diet</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h5">Breakfast</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    options={baseline}
                                                    getOptionLabel={(option) => option.label}
                                                    defaultValue={[baseline[0], baseline[2]]}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container direction="column" spacing={3}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h5">Lunch</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    options={baseline}
                                                    getOptionLabel={(option) => option.label}
                                                    defaultValue={[baseline[1], baseline[5]]}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container direction="column" spacing={3}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Typography variant="h5">Dinner</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    multiple
                                                    options={baseline}
                                                    getOptionLabel={(option) => option.label}
                                                    defaultValue={[baseline[3], baseline[4]]}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <CardActions>
                        <Grid container spacing={2} justifyContent="left">
                            <Grid item>
                                <Button variant="contained" onClick={closeModal}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={closeModal}>
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Dialog>
            </Grid>
        </Grid>
    );
};
export default DietaryPreferencesModal;
