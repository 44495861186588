// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    proxymails: [],
    unreadCount: undefined,
    isChecked: false,
    emailDetails: false,
    selectedMail: {}
};

const slice = createSlice({
    name: 'proxymail',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET MAILS
        getMailsSuccess(state, action) {
            state.proxymails = action.payload.proxymails;
            state.unreadCount = action.payload.unreadCount;
        },

        // FILTER MAILS
        filterMailsSuccess(state, action) {
            state.proxymails = action.payload;
        },
        isChecked(state, action) {
            state.isChecked = action.payload;
        }
        // ,
        //     setEmailDetails(state, action) {
        //         state.emailDetails = action.payload;
        //     },
        //     setSelectedMail(state, action) {
        //         state.selectedMail = action.payload;
        //     }
    }
});

// Reducer
export default slice.reducer;
// export const { isChecked, setEmailDetails, setSelectedMail } = slice.actions;
export const { isChecked } = slice.actions;

// ----------------------------------------------------------------------

export function getMails() {
    return async () => {
        try {
            const response = await axios.get('/api/proxymails/list');
            dispatch(slice.actions.getMailsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterMails(filter) {
    return async () => {
        try {
            const response = await axios.post('/api/proxymails/filter', { filter });
            dispatch(slice.actions.filterMailsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setImportant(id) {
    return async () => {
        try {
            await axios.post('/api/proxymails/setImportant', { id });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setStarred(id) {
    return async () => {
        try {
            await axios.post('/api/proxymails/setStarred', { id });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setRead(id) {
    return async () => {
        try {
            await axios.post('/api/proxymails/setRead', { id });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
