// third-party
import { sub } from 'date-fns';
import { Chance } from 'chance';

// project imports
import services from 'utils/mockAdapter';

const chance = new Chance();

// mail conversation
let mails = [
    {
        id: '#2Mail_Phoebe',
        subject: 'Schedule an Appointment With Dr. Roger Miles',
        isRead: false,
        important: true,
        starred: false,
        time: sub(new Date(), { days: 0, hours: 1, minutes: 45 }),
        topic: 'Schedule an Appointment With Dr. Roger Miles ',
        forums: false,
        attach: false,
        sent: chance.bool(),
        draft: chance.bool(),
        spam: false,
        trash: chance.bool(),
        profile: {
            avatar: 'user-4.png',
            name: 'Susan Richards',
            email: 'roberthailey@mail.com',
            to: 'roberthailey@mail.com'
        },
        sender: {
            avatar: 'robert_hailey_patient.png',
            name: 'Robert Hailey',
            email: 'roberthailey@mail.com',
            to: 'roberthailey@mail.com',
            about: chance.paragraph({ sentences: 1 }),
            role: 'personal-care-team'
        },
        message: 'Please Schedule an Appointment With Dr. Roger Miles on next week.',
        attachments: []
    }
];

// filter functions
const getInboxMails = () => mails.filter((item) => !item.spam);
const getSentMails = () => mails.filter((item) => item.sent);
const getDraftMails = () => mails.filter((item) => item.draft);
const getSpamMails = () => mails.filter((item) => item.spam);
const getTrashMails = () => mails.filter((item) => item.trash);
const getStarredMails = () => mails.filter((item) => item.starred);
const getImportantMails = () => mails.filter((item) => item.important);
const getPromotionsMails = () => mails.filter((item) => item.promotions);
const getForumMails = () => mails.filter((item) => item.forums);

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/mails/list').reply(() => [
    200,
    {
        mails,
        unreadCount: {
            all: mails.filter((i) => !i.isRead).length,
            inbox: getInboxMails().filter((i) => !i.isRead).length,
            sent: getSentMails().filter((i) => !i.isRead).length,
            draft: getDraftMails().filter((i) => !i.isRead).length,
            spam: getSpamMails().filter((i) => !i.isRead).length,
            trash: getTrashMails().filter((i) => !i.isRead).length,
            starred: getStarredMails().filter((i) => !i.isRead).length,
            important: getImportantMails().filter((i) => !i.isRead).length,
            promotions: getPromotionsMails().filter((i) => !i.isRead).length,
            forums: getForumMails().filter((i) => !i.isRead).length
        }
    }
]);

services.onPost('/api/mails/setRead').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const mailIndex = mails.findIndex((i) => i.id === id);
        mails[mailIndex] = { ...mails[mailIndex], isRead: true };
        mails = [...mails];
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/mails/setImportant').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const mailIndex = mails.findIndex((i) => i.id === id);
        mails[mailIndex] = { ...mails[mailIndex], important: !mails[mailIndex].important };
        mails = [...mails];
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/mails/setStarred').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const mailIndex = mails.findIndex((i) => i.id === id);
        mails[mailIndex] = { ...mails[mailIndex], starred: !mails[mailIndex].starred };
        mails = [...mails];
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/mails/filter').reply((config) => {
    try {
        const { filter } = JSON.parse(config.data);

        let result = [];
        switch (filter) {
            case 'inbox':
                result = getInboxMails();
                break;
            case 'sent':
                result = getSentMails();
                break;
            case 'draft':
                result = getDraftMails();
                break;
            case 'spam':
                result = getSpamMails();
                break;
            case 'trash':
                result = getTrashMails();
                break;
            case 'starred':
                result = getStarredMails();
                break;
            case 'important':
                result = getImportantMails();
                break;
            case 'promotions':
                result = getPromotionsMails();
                break;
            case 'forums':
                result = getForumMails();
                break;
            case 'all':
            default:
                result = mails;
                break;
        }

        return [200, result];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});
