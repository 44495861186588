// assets
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import ChooseBreakfast from './tabs/ChooseBreakfast';
import ChooseLunch from './tabs/ChooseLunch';
import ChooseSnacks from './tabs/ChooseSnacks';
import ChooseDinner from './tabs/ChooseDinner';
import ChooseOther from './tabs/ChooseOther';

// tabs panel
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: (
            <>
                <Typography variant="tabText">Breakfast</Typography>
            </>
        )
        // icon: <AccountCircleTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Lunch</Typography>
            </>
        )
        // icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Snacks</Typography>
            </>
        )
        // icon: <LibraryBooksTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Dinner</Typography>
            </>
        )
        // icon: <LockTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: (
            <>
                <Typography variant="tabText">Other</Typography>
            </>
        )
        // icon: <LockTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    }
];

const CreateMealPlanTabs = () => {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sx={{ pt: '8px !important' }}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    sx={{
                        mb: 1,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 0,
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.primary.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        }
                    }}
                >
                    {tabsOption.map((tab, index) => (
                        <Tab key={index} component={Link} to="#" icon={tab.icon} label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ChooseBreakfast />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ChooseLunch />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ChooseSnacks />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ChooseDinner />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <ChooseOther />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default CreateMealPlanTabs;
