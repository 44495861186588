import { useState } from 'react';
// material-ui
import { MenuItem, InputLabel, Select, FormControl, useTheme, Typography, Grid, Stack, TextField, FormHelperText } from '@mui/material';
import { gridSpacing } from 'store/constant';
// project imports

import { useIntl, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';

// ==============================|| MAIN LOGO ||============================== //

const ChooseAccountMenuSection = () => {
    const theme = useTheme();
    const intl = useIntl();
    const [accountType, setAccountType] = useState('0'); //    "1" is the default value in this scenario. Replace it with the default value that suits your needs.
    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
    };

    return (
        <Grid container spacing={2} alignItems="center">
            {/* <Grid item xs={12} sm={9} lg={12}>
                <FormControl fullWidth>
                    <InputLabel>Choose Account Type</InputLabel>
                    <Select
                        labelId="accountType-select"
                        id="accountType"
                        name="accountType"
                        value={accountType}
                        defaultValue={accountType}
                        onChange={handleAccountTypeChange}
                    >
                        <MenuItem value={0}>My Account</MenuItem>
                        <MenuItem value={1}>Robert Hailey</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}

            <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <InputLabel id="account-type-select">Choose Account Type</InputLabel>
                    <Select
                        labelId="account-type-select"
                        id="account-type"
                        name="account-type"
                        value={accountType}
                        onChange={handleAccountTypeChange}
                        label="Choose Account Type"
                    >
                        <MenuItem value={0}>My Account</MenuItem>
                        <MenuItem value={1}>Robert Hailey</MenuItem>
                    </Select>
                    {/* {formik.errors.age && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {' '}
                                    {formik.errors.age}{' '}
                                </FormHelperText>
                            )} */}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ChooseAccountMenuSection;
