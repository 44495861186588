// material-ui
import { Table, TableBody, Grid, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));
// table data
function createData(type, substance, substancecode, reaction, date, status) {
    return { type, substance, substancecode, reaction, date, status };
}

const rows = [createData('Medication', 'Aspirin', '1191', 'Hives/Rash', '02/10/2020', 'Active')];

// ==============================|| TABLE - DENSE ||============================== //

export default function AllergiesAdverseReactionTable() {
    const theme = useTheme();
    // const cardStyle = {
    //     background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    //     border: '1px solid',
    //     borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
    //     minWidth: 600
    // };
    return (
        // <Card sx={cardStyle}>
        <SubCard>
            <Grid item>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Allergies and Adverse Reactions
                </MuiTypography>
            </Grid>
            <TableContainer sx={{ minWidth: 500, border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Type</StyledTableCell>
                            <StyledTableCell align="left">Substance</StyledTableCell>
                            <StyledTableCell align="left">Substance RxNorm Code</StyledTableCell>
                            <StyledTableCell align="left">Reaction</StyledTableCell>
                            <StyledTableCell align="left">Date Diagnosed</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            {/* <TableCell sx={{ pr: 3 }} align="right">
                                Protein&nbsp;(g)
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover key={row.name}>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">{row.substance}</TableCell>
                                <TableCell align="left">{row.substancecode}</TableCell>
                                <TableCell align="left">{row.reaction}</TableCell>
                                <TableCell align="left">{row.date}</TableCell>
                                <TableCell align="left">{row.status}</TableCell>
                                {/* <TableCell sx={{ pr: 3 }} align="right">
                                    {row.protein}
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubCard>
        // </Card>
    );
}
