import {
    useTheme,
    Grid,
    Typography,
    Switch,
    FormControlLabel,
    Button,
    CardContent,
    IconButton,
    Card,
    Link,
    Stack,
    MenuItem,
    Menu,
    Badge,
    Chip
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExerciseActivities from '../activities/exercise/ExerciseActivities';
import ViewProgress from './viewprogress/ViewProgress';
import ReferenceMaterial from './referencematerial/ReferenceMaterial';
import { styled } from '@mui/styles';
import RestActivity from '../activities/rest';
import AttitudeActivity from '../activities/attitude';
import MedicationActivity from '../activities/medication';
import NutritionExerciseActivity from '../activities/exercise/NutritionExerciseActivity';
import MealPlanContainer from '../../../../../components/mealplan/MealPlanContainer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddReferenceMaterial from './referencematerial/AddReferenceMaterial';
import AddReferenceMaterialModalContent from './referencematerial/AddReferenceMaterialModalContent';

const GoalsCard = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [unreadCounts, setUnreadCounts] = useState('1');

    const handleClickSort = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSort = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false);
    const [nopen, setNOpen] = useState(false);
    const [dietopen, setDietOpen] = useState(false);
    const [exerciseopen, setExerciseOpen] = useState(false);
    const [nexerciseopen, setNExerciseOpen] = useState(false);
    const [restopen, setRestOpen] = useState(false);
    const [attitudeopen, setAttitudeOpen] = useState(false);
    const [medicationopen, setMedicationOpen] = useState(false);
    const [viewReference, setViewReference] = useState(false);
    const [addReference, setAddReference] = useState(false);

    const collapseAll = () => {
        setViewReference(false);
        setAddReference(false);
    };
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        color: theme.palette.black.black,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    };
    return (
        <>
            {/* <Card sx={cardStyle}>
                <CardContent sx={{ p: '10px !important' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={11} md={11} lg={11}>
                            <IconButton sx={{ pl: 0 }} onClick={handleClick}>
                                <Typography variant="h4">Goals </Typography>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} align="right">
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                <ExpandCircleDownOutlinedIcon color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {open && (
                <>
                    <MealPlanContainer />
                </>
            )} */}
            <Grid item xs={12} lg={12} md={12}>
                {!dietopen && !exerciseopen && !nexerciseopen && !restopen && !attitudeopen && !medicationopen && (
                    <>
                        {' '}
                        <Grid item container xs={12} lg={12} md={12} alignItems="center" sx={{ pb: 1, mt: -2 }}>
                            <Grid item xs={1} lg={1} md={1}>
                                <Typography variant="dashboardCardContent">
                                    <b>Goals</b>
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={2} lg={2} md={2} align="center">
                        <Button size="small" variant="contained">
                            Add Goals
                        </Button>
                    </Grid> */}
                            <Grid item xs={11} lg={11} md={11} align="right">
                                <FormControlLabel
                                    value="end"
                                    control={<Switch color="primary" />}
                                    label="Show Inactive Goals"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                        <Card style={{ border: '1px solid', borderColor: '#e9e8e8' }}>
                            <CardContent sx={cardStyle}>
                                <Grid container>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Grid container>
                                            <Grid item xs={10} align="left" sx={{ pb: 1 }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <FiberManualRecordIcon sx={{ color: 'success.main' }} />

                                                    <Typography variant="dashboardCardContent">
                                                        <b>Weight Goal</b>
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={2} align="right">
                                                <IconButton size="small" sx={{ mt: -0.75, mr: -0.75 }} onClick={handleClickSort}>
                                                    <MoreHorizOutlinedIcon />
                                                </IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseSort}
                                                >
                                                    <MenuItem onClick={handleCloseSort}>Inactive</MenuItem>
                                                    <MenuItem onClick={handleCloseSort}>Edit</MenuItem>
                                                    <MenuItem onClick={handleCloseSort}>Delete</MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sx={{ pt: 1 }}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="subTitle1">
                                                    <b>Start Date: </b> 05/01/2022
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography variant="subTitle1">
                                                    <b>End Date:</b> 05/22/2022
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="subTitle1">
                                                    <b>Goal Source:</b> Sutter Health
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pt: 1 }}>
                                        <Typography variant="subTitle1">
                                            <b>Goal Target: </b> 150 lbs
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, ml: 0, mb: 0 }}>
                                        {/* <GoalsWhiteCard goal1="Diet" setMealView={!} goal2="Exercise" goal3="Medication" /> */}
                                        <Grid container alignItems="center">
                                            {/* <Grid item xs={3} lg={3} md={3}>
                                                <Typography variant="subTitle1">
                                                    <b>Reference Materials:</b>
                                                </Typography>
                                            </Grid> */}
                                            <Grid item xs={12} lg={12} md={12}>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <>
                                                        <AnimateButton>
                                                            <Button
                                                                onClick={() => {
                                                                    collapseAll();
                                                                    setViewReference(!viewReference);
                                                                }}
                                                            >
                                                                Reference Materials&nbsp;&nbsp;
                                                                <Chip color="error" size="small" label={2} />
                                                            </Button>
                                                        </AnimateButton>
                                                        {/* <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                            DietNutrtitionGuidelines.pdf&nbsp;&nbsp;
                                                        </Link> */}
                                                        {/* <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                            https://www.youtube.com/watch?v=MOyTc05g4oU
                                                        </Link> */}
                                                    </>
                                                    {/* <AnimateButton>
                                                        <Button
                                                            startIcon={<AddCircleIcon />}
                                                            size="medium"
                                                            variant="text"
                                                            onClick={() => {
                                                                collapseAll();
                                                                setAddReference(!addReference);
                                                            }}
                                                        >
                                                            Add Reference Materials
                                                        </Button>
                                                    </AnimateButton> */}
                                                    {/* <AddReferenceMaterial /> */}
                                                </Stack>
                                            </Grid>
                                            {/* {addReference && (
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                        <>
                                                            <Grid item container xs={12} lg={12} md={12}>
                                                                <Grid item xs={12} lg={12} md={12} sx={{ p: 1 }}>
                                                                    <Typography variant="h5">Add Reference Materials</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <AddReferenceMaterialModalContent />
                                                        </>
                                                    </Card>
                                                </Grid>
                                            )} */}
                                            {viewReference && (
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <Card
                                                        sx={{
                                                            p: 2,
                                                            pt: 0.5,
                                                            mt: 1,
                                                            border: '1px solid',
                                                            borderColor: theme.palette.common.grey
                                                        }}
                                                    >
                                                        <>
                                                            <Grid item container xs={12} lg={12} md={12} sx={{ p: 1 }} alignItems="center">
                                                                <Grid item xs={5} lg={5} md={5}>
                                                                    <Typography variant="h5">Reference Materials</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} lg={7} md={7} align="right">
                                                                    <AddReferenceMaterial title="Weight Goal" />
                                                                </Grid>
                                                            </Grid>
                                                            <ReferenceMaterial />
                                                        </>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, ml: 0, mb: 0 }}>
                                        {/* <GoalsWhiteCard goal1="Diet" setMealView={!} goal2="Exercise" goal3="Medication" /> */}
                                        <Grid container>
                                            <Grid item xs={10} lg={10} md={10}>
                                                <Typography variant="subTitle1">
                                                    <b>Activities:</b>
                                                </Typography>
                                                &nbsp;
                                                <Link underline="none" sx={{ cursor: 'pointer' }} onClick={() => setDietOpen(!dietopen)}>
                                                    {/* <CustomizedBadge badgeContent={unreadCounts} color="error">
                                                        Diet
                                                    </CustomizedBadge> */}
                                                    Diet&nbsp;&nbsp;
                                                    <Chip color="error" size="small" label={unreadCounts} />
                                                </Link>
                                                {/* <CustomizedBadge badgeContent={unreadCounts} color="error" /> */}
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                <Link underline="none" sx={{ cursor: 'pointer' }} onClick={() => setRestOpen(!restopen)}>
                                                    Rest
                                                </Link>
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                <Link
                                                    underline="none"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => setExerciseOpen(!exerciseopen)}
                                                >
                                                    {/* <CustomizedBadge badgeContent={unreadCounts} color="error">
                                                        Exercise
                                                    </CustomizedBadge> */}
                                                    Exercise&nbsp;&nbsp;
                                                    <Chip color="error" size="small" label={unreadCounts} />
                                                </Link>
                                                {/* <CustomizedBadge badgeContent={unreadCounts} color="error" /> */}
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                <Link
                                                    underline="none"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => setAttitudeOpen(!attitudeopen)}
                                                >
                                                    Attitude
                                                </Link>
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                <Link
                                                    underline="none"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => setMedicationOpen(!medicationopen)}
                                                >
                                                    Medication
                                                </Link>
                                            </Grid>
                                            <Grid item xs={2} lg={2} md={2} align="right">
                                                <Button aria-label="expand row" size="small" variant="text" onClick={() => setOpen(!open)}>
                                                    View Progress
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {open && (
                                        <Grid item>
                                            <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                <>
                                                    <ViewProgress />
                                                </>
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            {/* <NutritionGoalsCard /> */}
                            <Card style={{ border: '1px solid', borderColor: '#e9e8e8' }}>
                                <CardContent sx={cardStyle}>
                                    <Grid container>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Grid container>
                                                <Grid item xs={10} align="left" sx={{ pb: 1 }}>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <FiberManualRecordIcon sx={{ color: 'error.main' }} />

                                                        <Typography variant="dashboardCardContent">
                                                            <b>Nutrition Goal</b>
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2} align="right">
                                                    <IconButton size="small" sx={{ mt: -0.75, mr: -0.75 }} onClick={handleClickSort}>
                                                        <MoreHorizOutlinedIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleCloseSort}
                                                    >
                                                        <MenuItem onClick={handleCloseSort}>Inactive</MenuItem>
                                                        <MenuItem onClick={handleCloseSort}>Edit</MenuItem>
                                                        <MenuItem onClick={handleCloseSort}>Delete</MenuItem>
                                                    </Menu>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ pt: 1 }}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography variant="subTitle1">
                                                        <b>Start Date: </b> 05/01/2022
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Typography variant="subTitle1">
                                                        <b>End Date:</b> 05/22/2022
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="subTitle1">
                                                        <b>Goal Source:</b> Sutter Health
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
                                            <Typography variant="subTitle1">
                                                <b>Goal Target: </b> 2500 cals (&nbsp; <b>Protein</b> - 15% | <b>Carb</b> - 5% |{' '}
                                                <b>Sodium</b> - 20% | <b>Cholesterol</b> - 10% | <b>Sugar</b> - 5% | <b>Fat</b> - 5% |{' '}
                                                <b>Fiber</b> - 18% | <b>Supplement</b> - 22% &nbsp;)
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xs={12} sx={{ pt: 1 }}>
                                        <Typography variant="subTitle1">
                                            <b>Protein</b> - 15% | <b>Carb</b> - 5% | <b>Sodium</b> - 20% | <b>Cholesterol</b> - 10% |{' '}
                                            <b>Sugar</b> - 5% | <b>Fat</b> - 5% | <b>Fiber</b> - 18% | <b>Supplement</b> - 22%
                                        </Typography>
                                    </Grid> */}
                                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, ml: 0, mb: 0 }}>
                                            {/* <GoalsWhiteCard goal1="Diet" setMealView={!} goal2="Exercise" goal3="Medication" /> */}
                                            <Grid container alignItems="center">
                                                {/* <Grid item xs={3} lg={3} md={3}>
                                                <Typography variant="subTitle1">
                                                    <b>Reference Materials:</b>
                                                </Typography>
                                            </Grid> */}
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        {/* <>
                                                            <AnimateButton>
                                                                <Link underline="none" sx={{ cursor: 'pointer' }}>
                                                                    Reference Materials&nbsp;&nbsp;
                                                                    <Chip color="error" size="small" label={2} />
                                                                </Link>
                                                            </AnimateButton>
                                                        </> */}
                                                        <AddReferenceMaterial title="Nutrition Goal" type={2} />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sx={{ mt: 2, ml: 0, mb: 0 }}>
                                            {/* <GoalsWhiteCard goal1="Diet" setMealView={!} goal2="Exercise" goal3="Medication" /> */}
                                            <Grid container>
                                                <Grid item xs={10} lg={10} md={10}>
                                                    <Typography variant="subTitle1">
                                                        <b>Activities:</b>
                                                    </Typography>
                                                    &nbsp;
                                                    <Link
                                                        variant="button"
                                                        underline="none"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => setDietOpen(!dietopen)}
                                                    >
                                                        {/* <CustomizedBadge badgeContent={unreadCounts} color="error">
                                                            Diet
                                                        </CustomizedBadge> */}
                                                        Diet&nbsp;&nbsp;
                                                        <Chip color="error" size="small" label={unreadCounts} />
                                                    </Link>
                                                    {/* <CustomizedBadge badgeContent={unreadCounts} color="error" /> */}
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <Link
                                                        underline="none"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => setRestOpen(!restopen)}
                                                    >
                                                        Rest
                                                    </Link>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <Link
                                                        underline="none"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => setNExerciseOpen(!nexerciseopen)}
                                                    >
                                                        Exercise
                                                    </Link>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <Link
                                                        underline="none"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => setAttitudeOpen(!attitudeopen)}
                                                    >
                                                        Attitude
                                                    </Link>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <Link
                                                        underline="none"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => setMedicationOpen(!medicationopen)}
                                                    >
                                                        Medication
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={2} lg={2} md={2} align="right">
                                                    <Button
                                                        aria-label="expand row"
                                                        size="small"
                                                        variant="text"
                                                        // onClick={() => setNOpen(!nopen)}
                                                    >
                                                        View Progress
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* {nopen && (
                                            <Grid item>
                                                <Card sx={{ p: 2, border: '1px solid', borderColor: theme.palette.common.grey }}>
                                                    <> View Nutrition Progress</>
                                                </Card>
                                            </Grid>
                                        )} */}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )}

                {dietopen && (
                    <Grid item container xs={12} lg={12} md={12} alignItems="center">
                        <MealPlanContainer back={setDietOpen} />
                    </Grid>
                )}

                {exerciseopen && (
                    <Grid item container xs={12} lg={12} md={12} alignItems="center">
                        <ExerciseActivities back={setExerciseOpen} />
                    </Grid>
                )}
                {nexerciseopen && (
                    <Grid item container xs={12} lg={12} md={12} alignItems="center">
                        <NutritionExerciseActivity back={setNExerciseOpen} />
                    </Grid>
                )}
                {restopen && (
                    <Grid item container xs={12} lg={12} md={12} alignItems="center">
                        <RestActivity back={setRestOpen} />
                    </Grid>
                )}
                {attitudeopen && (
                    <Grid item container xs={12} lg={12} md={12} alignItems="center">
                        <AttitudeActivity back={setAttitudeOpen} />
                    </Grid>
                )}
                {medicationopen && (
                    <Grid item container xs={12} lg={12} md={12} alignItems="center">
                        <MedicationActivity back={setMedicationOpen} />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default GoalsCard;
