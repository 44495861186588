import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack, useMediaQuery, Avatar, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth, drawerWidthClosed } from 'store/constant';
import MenuListCollapsed from './MenuListCollapsed';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CollapsedLogoSection from '../LogoSection/CollapsedLogoSection';
import { borderLeft } from '@mui/system';

// Constants for open and closed drawer widths
const OPEN_DRAWER_WIDTH = drawerWidth;
const CLOSED_DRAWER_WIDTH = drawerWidthClosed;

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const today = new Date();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const year = today.getFullYear();
    const { drawerOpen } = useSelector((state) => state.menu);

    const drawerWidth = drawerOpen ? OPEN_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH;

    const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'block' }, background: '#fff' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box sx={{ display: 'inline-flex', p: 2, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                    <Box sx={{ display: 'inline-flex', p: 2, mx: 'auto' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                background: '#fff',
                                color: '#999999',
                                cursor: 'pointer'
                            }}
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            color="inherit"
                        >
                            <KeyboardDoubleArrowLeftIcon />
                        </Avatar>
                    </Box>
                </Stack>
            </Box>
        ),
        [drawerOpen, dispatch]
    );

    const logoCollapsed = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Stack direction="row" alignItems="center">
                    <Box sx={{ display: 'inline-flex', mx: 'auto', pl: 2, pt: 2 }}>
                        <CollapsedLogoSection />
                    </Box>
                    <Box component="span" sx={{ pt: 2, display: { xs: 'none', md: 'block' } }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                background: 'transparent',
                                color: '#999999',
                                cursor: 'pointer'
                            }}
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            color="inherit"
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </Avatar>
                    </Box>
                </Stack>
            </Box>
        ),
        [drawerOpen, dispatch]
    );

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    paddingRight: '10px',
                    background: '#fff'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div>
                        <MenuList />
                    </div>

                    <Stack direction="row" justifyContent="center" sx={{ mb: 2, borderTop: '1px solid #eeee', pt: 2 }}>
                        <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
                            © 2024 Vital eCare {process.env.REACT_APP_VERSION}
                        </Typography>
                    </Stack>
                </div>
            </PerfectScrollbar>
        ),
        [theme.palette.grey[500], theme.palette.grey[500], theme.palette.grey[500]]
    );

    const drawerCollapsed = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    width: '100px'
                }}
            >
                <MenuListCollapsed />
                <Stack
                    direction="column"
                    justifyContent="center"
                    sx={{ mb: 2, px: 1, borderTop: '1px solid #eeeeee', pt: 2 }}
                    align="center"
                >
                    <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
                        © {year}
                    </Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
                        Vital eCare {process.env.REACT_APP_VERSION}
                    </Typography>
                </Stack>
            </PerfectScrollbar>
        ),
        [theme.palette.grey[500], theme.palette.grey[500], theme.palette.grey[500]]
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: drawerWidth }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: '#fff',
                        borderRight: 'none',
                        visibility: 'visible !important',
                        transform: 'none !important',
                        textTransform: 'uppercase',
                        [theme.breakpoints.up('md')]: {
                            top: '0'
                        }
                    },
                    '& .MuiListItemIcon-root': {
                        color: '#202263',
                        minWidth: '26px',
                        paddingRight: '15px'
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {!drawerOpen && logoCollapsed}
                {drawerOpen && drawer}
                {!drawerOpen && drawerCollapsed}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);
