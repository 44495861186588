// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports

const initialState = {
    sideDrawer: false,
    drawerData: {}
};

const dreamsSlice = createSlice({
    name: 'dashbaord',
    initialState,
    reducers: {
        openSideDrawer(state, action) {
            state.sideDrawer = action.payload;
        },
        setDrawerData(state, action) {
            state.drawerData = action.payload;
        }
    }
});

export default dreamsSlice.reducer;

export const { openSideDrawer, setDrawerData } = dreamsSlice.actions;
