import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import LogoCollapsed from 'ui-component/LogoCollapsed';

// ==============================|| MAIN LOGO ||============================== //

const CollapsedLogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <LogoCollapsed />
    </Link>
);

export default CollapsedLogoSection;
